import moment from 'moment'
import { useErrorService, useNotificationService } from '@abroad/components'
import React, { useEffect, useState } from 'react'
import { Modal, Button } from 'react-bootstrap'
import API from '../../utils/API'

const SessionList = ({ clientId, clientProfile, loggedInUser }) => {
  const [sessions, setSessions] = useState([])
  const [sessionIdAndIndex, setSessionIdAndIndex] = useState({})
  const [showModal, setShowModal] = useState(false)
  const Error = useErrorService()
  const Notification = useNotificationService()
  const loggedInUserIsAdmin = loggedInUser.admin || loggedInUser.isAdmin

  useEffect(() => {
    const fetchClientSurveyList = async () => {
      try {
        const { data } = await API.CoachAPI.coachingSession.getSessionList(
          clientId,
        )
        setSessions(data)
      } catch (e) {
        Error.showError(e)
      }
    }
    fetchClientSurveyList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const deleteSession = async () => {
    try {
      const { data } = await API.CoachAPI.coachingSession.deleteSession(
        sessionIdAndIndex.sessionId,
      )
      if (data?.status) {
        let newSessions = sessions
        newSessions.splice(sessionIdAndIndex.index, 1)
      }
      Notification.showNotification(data?.message, 'success')
      setShowModal(false)
    } catch (e) {
      setShowModal(false)
      Error.showError(e)
    }
  }

  return (
    <div class='form-group form-component-wrp'>
      <label class='form-label text-right'>Sessions</label>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Body>
          This can not be reverted. Are you sure you want to delete this
          session?
        </Modal.Body>
        <Modal.Footer>
          <Button variant='primary' onClick={() => deleteSession()}>
            OK
          </Button>
          <Button variant='secondary' onClick={() => setShowModal(false)}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      <div class='custom-templates' id='custom-templates'>
        {sessions.length > 0 ? (
          <table style={{ border: 'none' }}>
            <th class='session-row'>Type</th>
            <th class='session-row'>Date</th>
            <th class='session-row'>Note</th>
            <th class='session-row'>Action</th>
            {sessions.map((session, index) => (
              <tr>
                <td class='session-row'>
                  {session.teamId ? 'Team' : 'Personal'}
                </td>
                <td class='session-row'>
                  {loggedInUserIsAdmin ||
                  (session.teamId && session.coach._id === loggedInUser.id) ? (
                    <a
                      href={`/coach/clients/${clientProfile.id}/session/${session.id}`}
                      className='text-dark-600'>
                      {moment(session.date).format('MM/DD/YYYY')}
                    </a>
                  ) : loggedInUserIsAdmin || session.show ? (
                    <a
                      href={`/coach/clients/${clientProfile.id}/session/${session.id}`}
                      className='text-dark-600'>
                      {moment(session.date).format('MM/DD/YYYY')}
                    </a>
                  ) : (
                    moment(session.date).format('MM/DD/YYYY')
                  )}
                </td>
                <td class='session-row'>
                  {session.teamId && session.coach._id === loggedInUser.id ? (
                    '-'
                  ) : loggedInUserIsAdmin ? (
                    <>Taken by {session.coach.fullName}</>
                  ) : session.show ? (
                    'Taken by you'
                  ) : (
                    'Taken by another coach'
                  )}
                </td>
                <td class='session-row'>
                  {loggedInUserIsAdmin || session.show ? (
                    <a
                      onClick={() => {
                        setSessionIdAndIndex({
                          sessionId: session.id,
                          index: index,
                        })
                        setShowModal(true)
                      }}
                      class='red delete-session-link'>
                      Delete
                    </a>
                  ) : (
                    '-'
                  )}
                </td>
              </tr>
            ))}
          </table>
        ) : (
          <>
            No sessions
            <br />
          </>
        )}
        {clientProfile.coachId === loggedInUser.id && (
          <button
            type='button'
            onclick="addSession('{{u.id}}', '{{hasPlan}}')"
            class='btn btn-primary btn-small btn-add-session compare-btn'>
            Add Session
          </button>
        )}
      </div>
    </div>
  )
}

export default SessionList
