import React, { useState, useEffect, Suspense } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import {
  NotificationProvider,
  AuthContext,
  ABSpinner,
} from '@abroad/components'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import { ErrorView } from '../components'
import AuthService from '../utils/AuthService'
import { NotFound } from '../pages'
const AuthenticatedRoutes = React.lazy(() => import('./AuthenticatedRoutes'))

Bugsnag.start({
  apiKey: process.env.REACT_APP_BUGSNAG,
  plugins: [new BugsnagPluginReact()],
  releaseStage: process.env.NODE_ENV,
  enabledReleaseStages: ['production'],
})

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)

const AuthRoutes = ({ user, children }) => {
  if (!AuthService.isLogedIn() || (user && !user.isCoach)) {
    return (
      <Switch>
        <Route exact path='/404' component={NotFound} />
        <Redirect to='/404' />
      </Switch>
    )
  }
  return <>{children}</>
}

/**
 * Navigaton routes for the app
 *
 * @info [react-router-dom](https://reactrouter.com/web/guides/quick-start)
 */
const Routes = () => {
  const [auth, setAuth] = useState(false)
  const [user, setUser] = useState(null)
  useEffect(() => {
    if (AuthService.isLogedIn()) {
      setAuth(true)
      if (AuthService.getUserData()) {
        setUser(AuthService.getUserData())
      }
    } else {
      setAuth(false)
      setAuth(null)
    }
  }, [])

  useEffect(() => {
    if (user) {
      AuthService.setUserData(user)
    }
  }, [user])

  const updateUser = (newUser) => {
    setUser((prev) => ({ ...prev, ...newUser }))
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorView}>
      <AuthContext.Provider
        value={{ auth, setAuth, user, setUser: updateUser }}>
        <NotificationProvider>
          <Switch>
            <Route
              exact
              path='/'
              render={() => <Redirect to='/coach/clients' />}
            />
            <AuthRoutes user={user}>
              <Suspense fallback={<ABSpinner />}>
                <AuthenticatedRoutes />
              </Suspense>
            </AuthRoutes>
          </Switch>
        </NotificationProvider>
      </AuthContext.Provider>
    </ErrorBoundary>
  )
}

export default Routes
