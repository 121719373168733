import * as CryptoJS from 'crypto-js'
import Cookies from 'js-cookie'
/**
 * Store and get user data
 *
 * @class Auth
 */
class AuthService {
  constructor() {
    this.rememberMe = false
  }

  getCookiesPolicy() {
    return {
      expires: this.rememberMe ? 30 : 1,
      sameSite: 'strict',
      domain: `${process.env.REACT_APP_COOKIE_DOMAIN}`,
    }
  }

  getStorage() {
    return localStorage
  }

  setLoginState(isLogin, remeberMe = this.rememberMe) {
    this.rememberMe = remeberMe
    const cipherText = CryptoJS.AES.encrypt(
      JSON.stringify(isLogin),
      process.env.REACT_APP_CIPHER,
      { mode: CryptoJS.mode.CBC },
    ).toString()
    Cookies.set(
      `${process.env.REACT_APP_COOKIE_PREFIX}_ab_auth`,
      cipherText,
      this.getCookiesPolicy(),
    )
  }

  getLoginState() {
    try {
      const auth = Cookies.get(`${process.env.REACT_APP_COOKIE_PREFIX}_ab_auth`)
      const bytes = CryptoJS.AES.decrypt(auth, process.env.REACT_APP_CIPHER, {
        mode: CryptoJS.mode.CBC,
      })
      return JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
    } catch (e) {
      return null
    }
  }

  setUserData(data) {
    const cipherText = CryptoJS.AES.encrypt(
      JSON.stringify(data),
      process.env.REACT_APP_CIPHER,
      { mode: CryptoJS.mode.CBC },
    ).toString()
    const revisited = CryptoJS.AES.encrypt(
      JSON.stringify({ visited: true }),
      process.env.REACT_APP_CIPHER,
      { mode: CryptoJS.mode.CBC },
    ).toString()
    Cookies.set(
      `${process.env.REACT_APP_COOKIE_PREFIX}_ab_u`,
      cipherText,
      this.getCookiesPolicy(),
    ) // ab_u => abroad user
    Cookies.set(
      `${process.env.REACT_APP_COOKIE_PREFIX}_ab_rs`,
      revisited,
      this.getCookiesPolicy(),
    )
  }

  getUserData() {
    try {
      const user = Cookies.get(`${process.env.REACT_APP_COOKIE_PREFIX}_ab_u`)
      const bytes = CryptoJS.AES.decrypt(user, process.env.REACT_APP_CIPHER, {
        mode: CryptoJS.mode.CBC,
      })
      return JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
    } catch (e) {
      return null
    }
  }

  getUserId() {
    const user = this.getUserData()
    return user?._id || user?.id
  }

  removeData() {
    Cookies.remove('ab_auth')
    Cookies.remove('ab_u')
    Cookies.remove(
      `${process.env.REACT_APP_COOKIE_PREFIX}_ab_auth`,
      this.getCookiesPolicy(),
    )
    Cookies.remove(
      `${process.env.REACT_APP_COOKIE_PREFIX}_ab_u`,
      this.getCookiesPolicy(),
    )
  }

  isLogedIn() {
    const auth = this.getLoginState()
    if (auth) {
      return true
    } else {
      return false
    }
  }

  isUserRevisited() {
    if (Cookies.get(`${process.env.REACT_APP_COOKIE_PREFIX}_ab_rs`)) {
      return true
    }
    return false
  }
}

export default new AuthService()
