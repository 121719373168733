import React from 'react'
import { Row, Col, Form } from 'react-bootstrap'
import Select from 'react-select'
import classnames from 'classnames'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { useFormikContext } from 'formik'
import makeAnimated from 'react-select/animated'
import { isLGScreen } from '../../utils/utility'

const animatedComponents = makeAnimated()

const styles = {
  control: (provided, state) => ({
    border: state.isFocused ? '1px' : '0px',
    borderColor: state.isFocused ? '#80bdff' : '',
    borderRadius: '5px',
    boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(0, 123, 255, 0.25)' : '',
  }),
  valueContainer: (provided, state) => {
    let borderColor = 'darkgrey'
    if (state.selectProps?.isValid) {
      borderColor = '#509f92'
    }
    if (state.selectProps?.isInvalid) {
      borderColor = '#bc4c6a'
    }
    return {
      ...provided,
      width: '100%',
      minHeight: 'calc(1.5em + 1rem + 2px)',
      hight: 'auto',
      backgroundColor: '#fafafa',
      backgroundClip: 'padding-box',
      border: '1px solid',
      borderColor: borderColor,
      borderRadius: '5px',
      outline: 0,
    }
  },
  input: (provided) => ({
    ...provided,
    fontSize: '12pt',
    paddingLeft: '0.5rem',
    paddingRight: '0.5rem',
  }),
  placeholder: (provided) => ({
    ...provided,
    paddingLeft: '0.45rem',
    fontFamily: 'Montserrat',
    color: '#828282',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused || state.isSelected ? '#efc030' : '',
    ':active': {
      backgroundColor: '#efc030',
    },
  }),
  indicatorsContainer: () => ({
    display: 'none',
  }),
  multiValue: (provided) => ({
    ...provided,
    border: '1px solid darkgrey',
    borderRadius: '5px',
    // border-top-left-radius
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    borderLeft: '1px solid darkgrey',
    ':hover': {
      backgroundColor: 'transparent',
      color: 'darkgrey',
    },
  }),
}

const SelectionField = ({
  label,
  name,
  options,
  placeholder,
  disabledOptions,
  fullInputWidth,
}) => {
  const {
    values,
    touched,
    errors,
    handleBlur,
    setFieldTouched,
    setFieldValue,
  } = useFormikContext()
  const handleChange = (selectedOption) => {
    setFieldValue(name, selectedOption)
  }
  return (
    <Row noGutters xs={1} sm={1} md={1} lg={2}>
      {label && (
        <Col
          className={classnames('d-flex align-items-center', {
            'col-lg-2': label && fullInputWidth === undefined,
            'col-lg-12': fullInputWidth === 'true',
          })}>
          <Form.Group className={isLGScreen ? '' : 'mb-0'}>
            <Form.Label column={isLGScreen ? true : false}>{label}</Form.Label>
          </Form.Group>
        </Col>
      )}
      <Col
        className={classnames({
          'col-lg-10': label && fullInputWidth === undefined,
          'col-lg-12': fullInputWidth === 'true',
        })}>
        <Form.Group>
          <Select
            value={values[name]}
            closeMenuOnSelect={false}
            components={animatedComponents}
            isMulti
            isValid={
              touched.hasOwnProperty(name) && !errors.hasOwnProperty(name)
            }
            isInvalid={
              touched.hasOwnProperty(name) && errors.hasOwnProperty(name)
            }
            placeholder={placeholder}
            styles={styles}
            onFocus={() => {
              setFieldTouched(name, true, false)
            }}
            onChange={handleChange}
            onBlur={handleBlur}
            options={options}
            isOptionDisabled={(option) =>
              disabledOptions?.includes(option?.value)
            }
          />
        </Form.Group>
      </Col>
    </Row>
  )
}

SelectionField.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
}

SelectionField.defaultProps = {
  options: [],
  name: '',
  label: '',
}

export default withTranslation('form')(SelectionField)
