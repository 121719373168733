import { Client, Admin, Coach } from '@abroad/rest-client'

const ClientAPI = new Client({
  baseUrl: process.env.REACT_APP_API_ENDPOINT,
})

const AdminAPI = new Admin({
  baseUrl: process.env.REACT_APP_API_ENDPOINT,
})

const CoachAPI = new Coach({
  baseUrl: process.env.REACT_APP_API_ENDPOINT,
})

const API = {
  ClientAPI,
  AdminAPI,
  CoachAPI,
}

export default API
