import { useErrorService, ABSpinner } from '@abroad/components'
import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import classnames from 'classnames'
import { formatDate } from '../utils/date'
import API from '../utils/API'

const Clients = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [clients, setClients] = useState([])
  const [clientsList, setClientsList] = useState([])
  const Error = useErrorService()
  const [surveyList, setSurveyList] = useState([])
  const [searchTerm, setSearchTerm] = React.useState('')
  const [teams, setTeams] = useState([])
  const loggedInUser = {}
  const handleChange = (event) => {
    setSearchTerm(event.target.value)
  }

  useEffect(() => {
    const temp = [...clients]
    const results = temp.filter(
      (client) =>
        client.fullName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        client.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
        client.company.toLowerCase().includes(searchTerm.toLowerCase()),
    )
    setClientsList(results)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm])

  useEffect(() => {
    const fetchClients = async () => {
      try {
        setIsLoading(true)
        const { data } = await API.CoachAPI.coachClient.getClientList()
        setClients(data)
        setClientsList(data)
        setIsLoading(false)
      } catch (e) {
        Error.showError(e)
        setIsLoading(false)
      }
    }
    const fetchTeams = async () => {
      try {
        setIsLoading(true)
        const { data } = await API.CoachAPI.teams.getTeamList()
        setTeams(data)
        setIsLoading(false)
      } catch (e) {
        Error.showError(e)
        setIsLoading(false)
      }
    }
    fetchClients()
    fetchTeams()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const groupBy = (items, key) =>
    items.reduce(
      (result, item) => ({
        ...result,
        [item[key]]: [...(result[item[key]] || []), item],
      }),
      {},
    )

  const surveyListGrouped = groupBy(surveyList, 'clientId')

  const compare = (id) => {
    window.open(
      `/survey/comparison_report?survey=${surveyListGrouped[id][0].surveyId}&survey=${surveyListGrouped[id][1].surveyId}`,
    )
  }

  if (isLoading) {
    return <ABSpinner />
  }

  return (
    <>
      <div className='user-show-title-wrp'>
        <div className='container'>
          <div className='user-show-title'>
            <h3>Coach Dashboard</h3>
          </div>
        </div>
      </div>
      <div className='container'>
        <div className='row coach-dashboard'>
          <div className='col-xs-12 width-100'>
            <input
              className='coach-user-search'
              type='text'
              placeholder='Search'
              value={searchTerm}
              onChange={handleChange}
            />
            {clientsList.length > 0 && (
              <>
                <h2>Clients</h2>
                <div className='table-responsive'>
                  <table className='table table-striped'>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Full Name</th>
                        <th>Company</th>
                        <th>Affiliation</th>
                        <th>Assessments Results</th>
                        <th>Compare</th>
                      </tr>
                    </thead>
                    <tbody>
                      {clientsList.map((client, index) => (
                        <tr>
                          <td>{index + 1}</td>
                          <td>
                            <NavLink
                              to={`/coach/clients/${client._id}`}
                              className='text-dark-600'>
                              {client.fullName}
                              <br />
                              {client.email}
                            </NavLink>
                            <br /> Plan:{' '}
                            {client.planCode.charAt(0).toUpperCase() +
                              client.planCode.slice(1)}
                          </td>
                          <td>
                            {client.company ? client.company : 'Not Specified'}
                          </td>
                          <td>
                            {client.affiliation.length > 0
                              ? client.affiliation.join(', ')
                              : 'Not Specified'}
                          </td>
                          <td width='40%'>
                            {client.surveys.map((s) => (
                              <div>
                                <input
                                  type='checkbox'
                                  onClick={() => {
                                    let arr = [...surveyList]
                                    const index = arr.findIndex(
                                      (e) =>
                                        e.clientId === client._id &&
                                        e.surveyId === s._id,
                                    )
                                    if (index > -1) {
                                      arr.splice(index, 1)
                                    } else {
                                      arr.push({
                                        clientId: client._id,
                                        surveyId: s._id,
                                      })
                                    }
                                    setSurveyList(arr)
                                  }}
                                  className='survey-link'
                                  id={s._id}
                                />
                                <a
                                  className='text-dark-600'
                                  href={`/survey/${s._id}`}
                                  target='_blank'
                                  rel='noreferrer'>
                                  {' '}
                                  Assessments Results -{' '}
                                  {formatDate(s.createdAt)}
                                </a>
                                <br />
                              </div>
                            ))}
                          </td>
                          <td>
                            <button
                              className={classnames(
                                'btn btn-primary btn-small compare-btn',
                                {
                                  'compare-btn-disabled': !(
                                    surveyListGrouped[client._id] &&
                                    surveyListGrouped[client._id].length === 2
                                  ),
                                },
                              )}
                              onClick={() => compare(client._id)}
                              disabled={
                                !(
                                  surveyListGrouped[client._id] &&
                                  surveyListGrouped[client._id].length === 2
                                )
                              }>
                              Compare
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </>
            )}
            {clientsList.length === 0 && <div>No Clients.</div>}
          </div>
        </div>
      </div>
      <hr />
      <div className='container'>
        <div className='row survey-result d-block'>
          <div className='col-xs-12'>
            {teams.length > 0 ? (
              <>
                <h2>Teams</h2>
                <div className='table-responsive'>
                  <table className='table table-striped'>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Team Name</th>
                        <th>Company</th>
                        <th>Abroad Liaison</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {teams.map((team, index) => (
                        <tr>
                          <td>{index + 1}</td>
                          <td>{team.name}</td>
                          <td>
                            {team.company ? team.company : 'Not Specified'}
                          </td>
                          <td>{team.abroadLiaison.fullName}</td>
                          <td>
                            <button
                              className='btn btn-primary btn-small compare-btn'
                              id='{{team._id}}'
                              onclick='viewTeam(this.id)'>
                              {loggedInUser.admin || loggedInUser.isAdmin
                                ? 'Manage team'
                                : 'View'}
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </>
            ) : (
              'No Teams.'
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default withTranslation('translation')(Clients)
