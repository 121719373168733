import React, { Suspense } from 'react'
import { I18nextProvider } from 'react-i18next'
import { Router } from 'react-router-dom'
import { history } from '@abroad/components'
import { Helmet } from 'react-helmet'
import Routes from './routes'
import i18n from './utils/i18n'
import './styles/index.css'
import '@abroad/components/dist/index.css'

function App() {
  return (
    <>
      <Helmet>
        <link rel='canonical' href={process.env.REACT_APP_DOMAIN}></link>
        <meta property='og:url' content={process.env.REACT_APP_DOMAIN}></meta>
        <meta
          property='twitter:url'
          content={process.env.REACT_APP_DOMAIN}></meta>
      </Helmet>
      <Suspense fallback=''>
        <I18nextProvider i18n={i18n}>
          <Router history={history}>
            <Routes />
          </Router>
        </I18nextProvider>
      </Suspense>
    </>
  )
}

export default App
