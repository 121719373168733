import React, { useEffect, useState } from 'react'
import { Modal, Button } from 'react-bootstrap'
import { useErrorService } from '@abroad/components'
import { formatDate } from '../../utils/date'
import API from '../../utils/API'

const Reports = ({
  plan = { Report360Plan: false },
  user = {},
  clientProfileId,
  loggedInUserIsAdmin,
  clientId,
}) => {
  const [data, setData] = useState([])
  const [documents, setDocuments] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [reportIdAndIndex, setReportIdAndIndex] = useState({})
  const Error = useErrorService()

  const clientDocumentType = {
    1: 'Coaching Agreement',
    2: '360',
    3: 'Impact',
  }

  useEffect(() => {
    const fetchReports = async () => {
      const { data } = await API.CoachAPI.document.getClientDocumentList(
        clientId,
      )
      setData(data)
      setDocuments(data)
    }
    fetchReports()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const deleteReport = async () => {
    try {
      const { data } = await API.CoachAPI.document.deleteClientDocument(
        reportIdAndIndex.reportId,
        clientId,
      )
      if (data?.status) {
        let newSessions = documents
        newSessions.splice(reportIdAndIndex.index, 1)
      }
      Notification.showNotification(data?.message, 'success')
      setShowModal(false)
    } catch (e) {
      setShowModal(false)
      Error.showError(e)
    }
  }

  const downloadDocument = async (document) => {
    try {
      const { data } = await API.CoachAPI.document.getDownloadDocumentUrl(
        clientId,
        document._id,
        document.type,
        document.fileName,
      )
      if (data) {
        window.open(data.url)
      }
    } catch (e) {
      console.log(data)
      Error.showError(e)
    }
  }

  return (
    <>
      <div className='form-group form-component-wrp'>
        <Modal show={showModal} onHide={() => setShowModal(false)}>
          <Modal.Body>
            This can not be reverted. Are you sure you want to delete this
            document?
          </Modal.Body>
          <Modal.Footer>
            <Button variant='primary' onClick={() => deleteReport()}>
              OK
            </Button>
            <Button variant='secondary' onClick={() => setShowModal(false)}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
        <label className='form-label text-right'>Reports</label>
        <div className='custom-templates' id='custom-templates'>
          {data && (
            <>
              {documents.map((d, index) => (
                <>
                  <a
                    className='text-dark-600 cursor-pointer'
                    onClick={() => downloadDocument(d)}
                    rel='noreferrer'
                    target='_blank'>
                    {d.type !== 1
                      ? `${clientDocumentType[d.type]} Report`
                      : 'Coaching Agreement'}{' '}
                    | {formatDate(d.uploadedAt)}
                  </a>
                  {loggedInUserIsAdmin && (
                    <a
                      onClick={() => {
                        setReportIdAndIndex({
                          reportId: d.id,
                          index: index,
                        })
                        setShowModal(true)
                      }}
                      className='red delete-doc-link'>
                      {' '}
                      Delete
                    </a>
                  )}
                  <br />
                </>
              ))}
            </>
          )}
          <span className='d-inline-block' tabindex='0'>
            <button
              type='button'
              onclick="reports('360')"
              title={
                !plan.Report360Plan
                  ? '360 Report is not included in plan. Please contact administrator for further assistance.'
                  : null
              }
              className='btn btn-primary btn-small compare-btn'
              disabled={!plan.Report360Plan || plan.Report360Plan === null}>
              Log 360 Report
            </button>
          </span>
          <span className='d-inline-block' tabindex='0'>
            <button
              type='button'
              onclick="reports('Impact')"
              title={
                !plan.ImpactReportPlan || plan.ImpactReportPlan === null
                  ? 'Impact Report is not included in plan. Please contact administrator for further assistance.'
                  : null
              }
              className='btn btn-primary btn-small compare-btn ml-1'
              disabled={
                !plan.ImpactReportPlan || plan.ImpactReportPlan === null
              }>
              Log Impact Report
            </button>
          </span>
          {(user.admin || user.isAdmin) && (
            <>
              <label
                for='CoachingAgreement'
                className='CoachingAgreement label-button btn btn-primary btn-small btn-add-session'>
                Upload Coaching Agreement
              </label>
              <input
                type='file'
                id='CoachingAgreement'
                className='doc-upload'
                accept='.pdf,.docx'
              />
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default Reports
