import React from 'react'
import { useTranslation } from 'react-i18next'
import { InputField } from '../form'
import Labels from '../../constants/labels'

const PriorityField = ({ disabledOptions, ...rest }) => {
  const { t } = useTranslation('form')
  const formLabel = t('placeholder')
  return (
    <InputField
      as='select'
      type='text'
      size='lg'
      sm='10'
      placeholder='Select One'
      defaultValue=''
      {...rest}>
      <option disabled value=''>
        {formLabel['Select One']}
      </option>
      {Labels.growthList.map((growthType) => (
        <option
          disabled={disabledOptions.includes(growthType)}
          key={growthType}
          value={growthType}>
          {growthType}
        </option>
      ))}
    </InputField>
  )
}

export default PriorityField
