import React from 'react'
import { InputField } from '../form'
import PropTypes from 'prop-types'

const SelectionField = ({ options, ...rest }) => {
  return (
    <InputField
      as='select'
      type='text'
      size='lg'
      placeholder='Select One'
      sm='10'
      defaultValue=''
      {...rest}>
      <option disabled value=''>
        Select One
      </option>
      {options.map((option) => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </InputField>
  )
}

SelectionField.propsTypes = {
  label: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default SelectionField
