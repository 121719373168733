"use strict";

var _interopRequireDefault = require("D:/Code/abroad-client/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _classCallCheck2 = _interopRequireDefault(require("D:/Code/abroad-client/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/classCallCheck"));

var _RestClient = _interopRequireDefault(require("./RestClient"));

var _onboarding = _interopRequireDefault(require("./client/onboarding"));

var _survey = _interopRequireDefault(require("./client/survey"));

var _user = _interopRequireDefault(require("./client/user"));

var _intention = _interopRequireDefault(require("./client/intention"));

var _selfReflection = _interopRequireDefault(require("./client/selfReflection"));

var _referral = _interopRequireDefault(require("./client/referral"));

var _mediaLibrary = _interopRequireDefault(require("./client/mediaLibrary"));

var _programs = _interopRequireDefault(require("./client/programs"));

var _payment = _interopRequireDefault(require("./client/payment"));

var _session = _interopRequireDefault(require("./client/session"));

var Client = function Client() {
  var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  (0, _classCallCheck2["default"])(this, Client);
  var restClient = new _RestClient["default"]({
    baseUrl: options.baseUrl
  });
  this.onboarding = new _onboarding["default"](restClient);
  this.survey = new _survey["default"](restClient);
  this.user = new _user["default"](restClient);
  this.intention = new _intention["default"](restClient);
  this.selfReflection = new _selfReflection["default"](restClient);
  this.referral = new _referral["default"](restClient);
  this.mediaLibrary = new _mediaLibrary["default"](restClient);
  this.programs = new _programs["default"](restClient);
  this.payment = new _payment["default"](restClient);
  this.session = new _session["default"](restClient);
};

exports["default"] = Client;