import React, { useState } from 'react'
import { Form } from 'react-bootstrap'
import PropTypes from 'prop-types'
import { useField, ErrorMessage } from 'formik'
import classnames from 'classnames'
import { isLGScreen } from '../../utils/utility'

const InputField = React.forwardRef(
  (
    {
      formGroupProps: {
        className: formGroupClassName,
        ...otherFormGroupProps
      } = {},
      children,
      id,
      label,
      labelProps,
      renderChildren,
      size,
      className,
      showTooltip,
      customErrorMessage,
      showErrorMessage,
      type,
      placeholder,
      removeFormikField,
      onFocus,
      extraIcon,
      dataTooltip,
      ...rest
    },
    ref,
  ) => {
    const [field, meta, helpers] = useField({ ...rest, ...{ type: type } })
    const [showPassword, setShowPassword] = useState(false)
    const [renderPasswordShowIcon, setRenderPasswordShowIcon] = useState(false)

    const renderErrorMessages = (textColorClass = 'text-error') => {
      if (!showErrorMessage) {
        return null
      }
      if (customErrorMessage) {
        return customErrorMessage
      }
      return (
        <Form.Text className='text-muted font-montserrat font-semibold px-1 font-12'>
          {customErrorMessage ? (
            customErrorMessage
          ) : (
            <ErrorMessage
              component='span'
              name={rest?.name}
              className={classnames(
                'px-1 font-montserrat font-semibold transition',
                textColorClass,
                className,
              )}
            />
          )}
        </Form.Text>
      )
    }

    const onFocusField = () => {
      if (rest?.name === 'password') {
        helpers.setTouched(true, true)
      }
      if (type === 'password') {
        setRenderPasswordShowIcon(true)
      }
      if (onFocus) {
        onFocus()
      }
    }

    const renderPasswordIcon = () => {
      if (type === 'password' && renderPasswordShowIcon) {
        return (
          <div className='position-absolute password-visibility-icon'>
            {showPassword ? (
              <svg
                onClick={() => setShowPassword(false)}
                xmlns='http://www.w3.org/2000/svg'
                width='26.567'
                height='17.123'
                viewBox='0 0 26.567 17.123'>
                <g transform='translate(316 16.703)'>
                  <g transform='translate(-316 -23.5)'>
                    <g transform='translate(0 0)'>
                      <path
                        d='M161.189,221.5a5.189,5.189,0,1,0,5.189,5.189A5.195,5.195,0,0,0,161.189,221.5Zm0,8.821a3.632,3.632,0,1,1,3.632-3.632A3.636,3.636,0,0,1,161.189,230.321Z'
                        transform='translate(-147.905 -211.33)'
                      />
                      <path
                        d='M26.385,164.561a33.921,33.921,0,0,0-4.118-3.994c-3.3-2.7-6.324-4.067-8.984-4.067S7.6,157.868,4.3,160.567a33.915,33.915,0,0,0-4.118,3.994.778.778,0,0,0,0,1A33.916,33.916,0,0,0,4.3,169.556c3.3,2.7,6.324,4.067,8.984,4.067s5.682-1.368,8.984-4.067a33.911,33.911,0,0,0,4.118-3.994A.778.778,0,0,0,26.385,164.561Zm-5.1,3.79c-2.974,2.431-5.739,3.716-8,3.716-4.685,0-9.929-5.327-11.465-7.006a33.906,33.906,0,0,1,3.467-3.288c2.974-2.431,5.739-3.716,8-3.716,4.685,0,9.929,5.327,11.465,7.006A33.9,33.9,0,0,1,21.282,168.351Z'
                        transform='translate(0 -149.703)'
                      />
                      <path
                        d='M237.037,291.5a1.037,1.037,0,0,0-.778,1.723v.612a.778.778,0,1,0,1.557,0v-.612a1.037,1.037,0,0,0-.778-1.723Z'
                        transform='translate(-223.753 -277.697)'
                      />
                    </g>
                  </g>
                </g>
              </svg>
            ) : (
              <svg
                onClick={() => setShowPassword(true)}
                xmlns='http://www.w3.org/2000/svg'
                width='26.567'
                height='24.828'
                viewBox='0 0 26.567 24.828'>
                <defs>
                  <style>
                    {`.pv-a{fill:none;stroke:#000;stroke-linecap:round;stroke-width:2px;}`}
                  </style>
                </defs>
                <g transform='translate(316 20.704)'>
                  <g transform='translate(-316 -23.5)'>
                    <g transform='translate(0 0)'>
                      <line
                        className='pv-a'
                        x1='22'
                        y2='22'
                        transform='translate(2.732 4.21)'
                      />
                      <path
                        d='M161.189,221.5a5.189,5.189,0,1,0,5.189,5.189A5.195,5.195,0,0,0,161.189,221.5Zm0,8.821a3.632,3.632,0,1,1,3.632-3.632A3.636,3.636,0,0,1,161.189,230.321Z'
                        transform='translate(-147.905 -211.33)'
                      />
                      <path
                        d='M26.385,164.561a33.921,33.921,0,0,0-4.118-3.994c-3.3-2.7-6.324-4.067-8.984-4.067S7.6,157.868,4.3,160.567a33.915,33.915,0,0,0-4.118,3.994.778.778,0,0,0,0,1A33.916,33.916,0,0,0,4.3,169.556c3.3,2.7,6.324,4.067,8.984,4.067s5.682-1.368,8.984-4.067a33.911,33.911,0,0,0,4.118-3.994A.778.778,0,0,0,26.385,164.561Zm-5.1,3.79c-2.974,2.431-5.739,3.716-8,3.716-4.685,0-9.929-5.327-11.465-7.006a33.906,33.906,0,0,1,3.467-3.288c2.974-2.431,5.739-3.716,8-3.716,4.685,0,9.929,5.327,11.465,7.006A33.9,33.9,0,0,1,21.282,168.351Z'
                        transform='translate(0 -149.703)'
                      />
                      <path
                        d='M237.037,291.5a1.037,1.037,0,0,0-.778,1.723v.612a.778.778,0,1,0,1.557,0v-.612a1.037,1.037,0,0,0-.778-1.723Z'
                        transform='translate(-223.753 -277.697)'
                      />
                    </g>
                  </g>
                </g>
              </svg>
            )}
          </div>
        )
      }
      return null
    }

    const renderLabel = () => {
      if (label) {
        return (
          <Form.Label {...labelProps} title={dataTooltip}>
            {label}
            {extraIcon}
          </Form.Label>
        )
      }
      return null
    }

    return (
      <Form.Group
        controlId={id || `control-${rest?.name}-${Math.random()}`}
        className={classnames(formGroupClassName)}
        {...otherFormGroupProps}>
        {renderErrorMessages()}
        <span className='d-flex position-relative align-items-center'>
          <div className='d-lg-flex align-items-center w-100'>
            {renderLabel()}
            <Form.Control
              ref={ref}
              className={classnames(
                'border-radius-5 transition',
                {
                  'password-visibility-input':
                    type === 'password' && renderPasswordShowIcon,
                },
                className,
              )}
              isValid={meta.touched && !meta.error}
              isInvalid={meta.touched && meta.error}
              onFocus={onFocusField}
              size={size || isLGScreen ? size : 'lg'}
              type={type === 'password' && showPassword ? 'text' : type}
              placeholder={placeholder}
              {...(!removeFormikField && rest.name !== 'company' ? field : {})}
              {...rest}>
              {children || renderChildren}
            </Form.Control>
          </div>
          {renderPasswordIcon()}
        </span>
      </Form.Group>
    )
  },
)

InputField.defaultProps = {
  showTooltip: false,
  removeFormikField: false,
  type: 'text',
  showErrorMessage: false,
}

InputField.propTypes = {
  removeFormikField: PropTypes.bool,
  showErrorMessage: PropTypes.bool,
}

export default InputField
