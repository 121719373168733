const countriesData = {
  Afghanistan: { ct: 'Asia' },
  'Åland Islands': { ct: 'Europe' },
  Albania: { ct: 'Europe' },
  Algeria: { ct: 'Africa' },
  'American Samoa': { ct: 'Oceania' },
  Andorra: { ct: 'Europe' },
  Angola: { ct: 'Africa' },
  Anguilla: { ct: 'North America' },
  Antarctica: { ct: 'Antarctica' },
  'Antigua & Barbuda': { ct: 'North America' },
  Argentina: { ct: 'South America' },
  Armenia: { ct: 'Asia' },
  Aruba: { ct: 'North America' },
  Australia: { ct: 'Oceania' },
  Austria: { ct: 'Europe' },
  Azerbaijan: { ct: 'Asia' },
  Bahamas: { ct: 'North America' },
  Bahrain: { ct: 'Asia' },
  Bangladesh: { ct: 'Asia' },
  Barbados: { ct: 'North America' },
  Belarus: { ct: 'Europe' },
  Belgium: { ct: 'Europe' },
  Belize: { ct: 'North America' },
  Benin: { ct: 'Africa' },
  Bermuda: { ct: 'North America' },
  Bhutan: { ct: 'Asia' },
  Bolivia: { ct: 'South America' },
  'Bosnia & Herzegovina': { ct: 'Europe' },
  Botswana: { ct: 'Africa' },
  Brazil: { ct: 'South America' },
  'British Indian Ocean Territory': { ct: 'Africa' },
  'British Virgin Islands': { ct: 'North America' },
  Brunei: { ct: 'Asia' },
  Bulgaria: { ct: 'Europe' },
  'Burkina Faso': { ct: 'Africa' },
  Burundi: { ct: 'Africa' },
  Cambodia: { ct: 'Asia' },
  Cameroon: { ct: 'Africa' },
  Canada: { ct: 'North America' },
  'Cape Verde': { ct: 'Africa' },
  'Cayman Islands': { ct: 'North America' },
  'Central African Republic': { ct: 'Africa' },
  Chad: { ct: 'Africa' },
  Chile: { ct: 'South America' },
  China: { ct: 'Asia' },
  'Christmas Island': { ct: 'Oceania' },
  'Cocos (Keeling) Islands': { ct: 'Oceania' },
  Colombia: { ct: 'South America' },
  Comoros: { ct: 'Africa' },
  'Congo - Brazzaville': { ct: 'Africa' },
  'Congo - Kinshasa': { ct: 'Africa' },
  'Cook Islands': { ct: 'Oceania' },
  'Costa Rica': { ct: 'North America' },
  'Côte d’Ivoire': { ct: 'Africa' },
  Croatia: { ct: 'Europe' },
  Cuba: { ct: 'North America' },
  Curaçao: { ct: 'North America' },
  Cyprus: { ct: 'Asia' },
  Czechia: { ct: 'Europe' },
  Denmark: { ct: 'Europe' },
  Djibouti: { ct: 'Africa' },
  Dominica: { ct: 'North America' },
  'Dominican Republic': { ct: 'North America' },
  Ecuador: { ct: 'South America' },
  Egypt: { ct: 'Africa' },
  'El Salvador': { ct: 'North America' },
  'Equatorial Guinea': { ct: 'Africa' },
  Eritrea: { ct: 'Africa' },
  Estonia: { ct: 'Europe' },
  Ethiopia: { ct: 'Africa' },
  'Falkland Islands': { ct: 'South America' },
  'Faroe Islands': { ct: 'Europe' },
  Fiji: { ct: 'Oceania' },
  Finland: { ct: 'Europe' },
  France: { ct: 'Europe' },
  'French Guiana': { ct: 'South America' },
  'French Polynesia': { ct: 'Oceania' },
  'French Southern Territories': { ct: 'Africa' },
  Gabon: { ct: 'Africa' },
  Gambia: { ct: 'Africa' },
  Georgia: { ct: 'Asia' },
  Germany: { ct: 'Europe' },
  Ghana: { ct: 'Africa' },
  Gibraltar: { ct: 'Europe' },
  Greece: { ct: 'Europe' },
  Greenland: { ct: 'North America' },
  Grenada: { ct: 'North America' },
  Guadeloupe: { ct: 'North America' },
  Guam: { ct: 'Oceania' },
  Guatemala: { ct: 'North America' },
  Guernsey: { ct: 'Europe' },
  Guinea: { ct: 'Africa' },
  'Guinea-Bissau': { ct: 'Africa' },
  Guyana: { ct: 'South America' },
  Haiti: { ct: 'North America' },
  Honduras: { ct: 'North America' },
  'Hong Kong SAR China': { ct: 'Asia' },
  Hungary: { ct: 'Europe' },
  Iceland: { ct: 'Europe' },
  India: { ct: 'Asia' },
  Indonesia: { ct: 'Asia' },
  Iran: { ct: 'Asia' },
  Iraq: { ct: 'Asia' },
  Ireland: { ct: 'Europe' },
  'Isle of Man': { ct: 'Europe' },
  Israel: { ct: 'Asia' },
  Italy: { ct: 'Europe' },
  Jamaica: { ct: 'North America' },
  Japan: { ct: 'Asia' },
  Jersey: { ct: 'Europe' },
  Jordan: { ct: 'Asia' },
  Kazakhstan: { ct: 'Asia' },
  Kenya: { ct: 'Africa' },
  Kiribati: { ct: 'Oceania' },
  Kosovo: { ct: 'Europe' },
  Kuwait: { ct: 'Asia' },
  Kyrgyzstan: { ct: 'Asia' },
  Laos: { ct: 'Asia' },
  Latvia: { ct: 'Europe' },
  Lebanon: { ct: 'Asia' },
  Lesotho: { ct: 'Africa' },
  Liberia: { ct: 'Africa' },
  Libya: { ct: 'Africa' },
  Liechtenstein: { ct: 'Europe' },
  Lithuania: { ct: 'Europe' },
  Luxembourg: { ct: 'Europe' },
  'Macau SAR China': { ct: 'Asia' },
  Macedonia: { ct: 'Europe' },
  Madagascar: { ct: 'Africa' },
  Malawi: { ct: 'Africa' },
  Malaysia: { ct: 'Asia' },
  Maldives: { ct: 'Asia' },
  Mali: { ct: 'Africa' },
  Malta: { ct: 'Europe' },
  'Marshall Islands': { ct: 'Oceania' },
  Martinique: { ct: 'North America' },
  Mauritania: { ct: 'Africa' },
  Mauritius: { ct: 'Africa' },
  Mayotte: { ct: 'Africa' },
  Mexico: { ct: 'North America' },
  Micronesia: { ct: 'Oceania' },
  Moldova: { ct: 'Europe' },
  Monaco: { ct: 'Europe' },
  Mongolia: { ct: 'Asia' },
  Montenegro: { ct: 'Europe' },
  Montserrat: { ct: 'North America' },
  Morocco: { ct: 'Africa' },
  Mozambique: { ct: 'Africa' },
  'Myanmar (Burma)': { ct: 'Asia' },
  Namibia: { ct: 'Africa' },
  Nauru: { ct: 'Oceania' },
  Nepal: { ct: 'Asia' },
  Netherlands: { ct: 'Europe' },
  'New Caledonia': { ct: 'Oceania' },
  'New Zealand': { ct: 'Oceania' },
  Nicaragua: { ct: 'North America' },
  Niger: { ct: 'Africa' },
  Nigeria: { ct: 'Africa' },
  Niue: { ct: 'Oceania' },
  'Norfolk Island': { ct: 'Oceania' },
  'North Korea': { ct: 'Asia' },
  'Northern Mariana Islands': { ct: 'Oceania' },
  Norway: { ct: 'Europe' },
  Oman: { ct: 'Asia' },
  Pakistan: { ct: 'Asia' },
  Palau: { ct: 'Oceania' },
  'Palestinian Territories': { ct: 'Asia' },
  Panama: { ct: 'North America' },
  'Papua New Guinea': { ct: 'Oceania' },
  Paraguay: { ct: 'South America' },
  Peru: { ct: 'South America' },
  Philippines: { ct: 'Asia' },
  'Pitcairn Islands': { ct: 'Oceania' },
  Poland: { ct: 'Europe' },
  Portugal: { ct: 'Europe' },
  'Puerto Rico': { ct: 'North America' },
  Qatar: { ct: 'Asia' },
  Réunion: { ct: 'Africa' },
  Romania: { ct: 'Europe' },
  Russia: { ct: 'Europe' },
  Rwanda: { ct: 'Africa' },
  Samoa: { ct: 'Oceania' },
  'San Marino': { ct: 'Europe' },
  'São Tomé & Príncipe': { ct: 'Africa' },
  'Saudi Arabia': { ct: 'Asia' },
  Senegal: { ct: 'Africa' },
  Serbia: { ct: 'Europe' },
  Seychelles: { ct: 'Africa' },
  'Sierra Leone': { ct: 'Africa' },
  Singapore: { ct: 'Asia' },
  'Sint Maarten': { ct: 'North America' },
  Slovakia: { ct: 'Europe' },
  Slovenia: { ct: 'Europe' },
  'Solomon Islands': { ct: 'Oceania' },
  Somalia: { ct: 'Africa' },
  'South Africa': { ct: 'Africa' },
  'South Georgia & South Sandwich Islands': { ct: 'South America' },
  'South Korea': { ct: 'Asia' },
  'South Sudan': { ct: 'Africa' },
  Spain: { ct: 'Europe' },
  'Sri Lanka': { ct: 'Asia' },
  'St. Barthélemy': { ct: 'North America' },
  'St. Helena': { ct: 'Africa' },
  'St. Kitts & Nevis': { ct: 'North America' },
  'St. Lucia': { ct: 'North America' },
  'St. Martin': { ct: 'North America' },
  'St. Pierre & Miquelon': { ct: 'North America' },
  'St. Vincent & Grenadines': { ct: 'North America' },
  Sudan: { ct: 'Africa' },
  Suriname: { ct: 'South America' },
  'Svalbard & Jan Mayen': { ct: 'Europe' },
  Swaziland: { ct: 'Africa' },
  Sweden: { ct: 'Europe' },
  Switzerland: { ct: 'Europe' },
  Syria: { ct: 'Asia' },
  Taiwan: { ct: 'Asia' },
  Tajikistan: { ct: 'Asia' },
  Tanzania: { ct: 'Africa' },
  Thailand: { ct: 'Asia' },
  'Timor-Leste': { ct: 'Asia' },
  Togo: { ct: 'Africa' },
  Tokelau: { ct: 'Oceania' },
  Tonga: { ct: 'Oceania' },
  'Trinidad & Tobago': { ct: 'North America' },
  'Tristan da Cunha': { ct: 'Africa' },
  Tunisia: { ct: 'Africa' },
  Turkey: { ct: 'Asia' },
  Turkmenistan: { ct: 'Asia' },
  'Turks & Caicos Islands': { ct: 'North America' },
  Tuvalu: { ct: 'Oceania' },
  'U.S. Outlying Islands': { ct: 'Oceania' },
  'U.S. Virgin Islands': { ct: 'North America' },
  Uganda: { ct: 'Africa' },
  Ukraine: { ct: 'Europe' },
  'United Arab Emirates': { ct: 'Asia' },
  'United Kingdom': { ct: 'Europe' },
  'United States': { ct: 'North America' },
  Uruguay: { ct: 'South America' },
  Uzbekistan: { ct: 'Asia' },
  Vanuatu: { ct: 'Oceania' },
  'Vatican City': { ct: 'Europe' },
  Venezuela: { ct: 'South America' },
  Vietnam: { ct: 'Asia' },
  'Wallis & Futuna': { ct: 'Oceania' },
  'Western Sahara': { ct: 'Africa' },
  Yemen: { ct: 'Asia' },
  Zambia: { ct: 'Africa' },
  Zimbabwe: { ct: 'Africa' },
}

const labels = {
  paymentCycle: [
    { key: 'OneTime', value: 'One Time Payment' },
    { key: 'Monthly', value: 'Monthly Payment' },
  ],
  planCycle: [
    { key: 'Monthly', value: 'Monthly' },
    { key: 'Annually', value: 'Annually' },
  ],
  ReportPlanOf360: [
    { key: 1, value: '1 Hour: 2-3 phone calls & No Report Write-up' },
    { key: 4, value: '4 Hours: (4 People x 30 minutes) + Report Write- up' },
    { key: 5, value: '5 Hours: (6 People x 30 minutes) + Report Write - up' },
    { key: 7, value: '7 Hours: (8 People x 30 minutes) + Report Write - up' },
  ],

  impactReportPlan: [
    {
      key: 0,
      value:
        '0 Hours: Quantitative Only(Assessment Pre & Post Results Comparison) & No Report Write- up',
    },
    {
      key: 2,
      value:
        '2 Hours: Quantitative + Qualitative Impact Report (Review of quant comparison, write up of qual reflections)',
    },
  ],

  timeZoneList: [
    { key: 'America/Adak', value: 'Hawaii-Aleutian Standard Time (GMT-10:00)' },
    { key: 'America/Yakutat', value: 'Alaska Standard Time (GMT-9:00)' },
    { key: 'America/Dawson', value: 'Pacific Standard Time(PST) (GMT-8:00)' },
    { key: 'America/Boise', value: 'Mountain Standard Time(MST) (GMT-7:00)' },
    { key: 'Canada/Central', value: 'Central Standard Time(CST) (GMT-6:00)' },
    { key: 'Chile/EasterIsland', value: 'Easter Island Time (GMT-6:00)' },
    { key: 'America/Atikokan', value: 'Eastern Standard Time(EST) (GMT-5:00)' },
    { key: 'America/Bogota', value: 'Colombia Time (GMT-5:00)' },
    { key: 'America/Guayaquil', value: 'Ecuador Time (GMT-5:00)' },
    { key: 'America/Havana', value: 'Cuba Standard Time (GMT-5:00)' },
    { key: 'America/Lima', value: 'Peru Time (GMT-5:00)' },
    { key: 'America/Caracas', value: 'Venezuela Time (GMT-4:-30)' },
    { key: 'America/Asuncion', value: 'Paraguay Time (GMT-4:00)' },
    { key: 'America/Guyana', value: 'Guyana Time (GMT-4:00)' },
    { key: 'America/La_Paz', value: 'Bolivia Time (GMT-4:00)' },
    { key: 'Atlantic/Stanley', value: 'Falkland Island Time (GMT-4:00)' },
    { key: 'Brazil/Acre', value: 'Amazon Time (GMT-4:00)' },
    { key: 'Canada/Atlantic', value: 'Atlantic Standard Time(AST) (GMT-4:00)' },
    { key: 'Chile/Continental', value: 'Chile Time (GMT-4:00)' },
    {
      key: 'America/St_Johns',
      value: 'Newfoundland Standard Time (GMT-3:-30)',
    },
    { key: 'America/Buenos_Aires', value: 'Argentine Time (GMT-3:00)' },
    { key: 'America/Cayenne', value: 'French Guiana Time (GMT-3:00)' },
    { key: 'America/Godthab', value: 'Western Greenland Time (GMT-3:00)' },
    {
      key: 'America/Miquelon',
      value: 'Pierre & Miquelon Standard Time (GMT-3:00)',
    },
    { key: 'America/Montevideo', value: 'Uruguay Time (GMT-3:00)' },
    { key: 'America/Paramaribo', value: 'Suritz Time (GMT-3:00)' },
    { key: 'Antarctica/Rothera', value: 'Rothera Time (GMT-3:00)' },
    { key: 'Brazil/East', value: 'Brasilia Time (GMT-3:00)' },
    {
      key: 'Atlantic/South_Georgia',
      value: 'South Georgia Standard Time (GMT-2:00)',
    },
    { key: 'Brazil/DeNoronha', value: 'Fernando de Noronha Time (GMT-2:00)' },
    { key: 'America/Scoresbysund', value: 'Eastern Greenland Time (GMT-1:00)' },
    { key: 'Atlantic/Azores', value: 'Azores Time (GMT-1:00)' },
    { key: 'Atlantic/Cape_Verde', value: 'Cape Verde Time (GMT-1:00)' },
    { key: 'Africa/Abidjan', value: 'Greenwich Mean Time(GMT) (GMT+0:00)' },
    { key: 'Africa/Accra', value: 'Ghana Mean Time (GMT+0:00)' },
    {
      key: 'Africa/Casablanca',
      value: 'Western European Time(WET) (GMT+0:00)',
    },
    { key: 'Europe/Stockholm', value: 'British Summer Time(BST) (GMT+1:00)' },
    { key: 'Africa/Algiers', value: 'Central European Time(CET) (GMT+1:00)' },
    { key: 'Africa/Bangui', value: 'Western African Time (GMT+1:00)' },
    { key: 'Africa/Blantyre', value: 'Central African Time (GMT+2:00)' },
    {
      key: 'Africa/Johannesburg',
      value: 'South Africa Standard Time (GMT+2:00)',
    },
    { key: 'Asia/Jerusalem', value: 'Israel Standard Time (GMT+2:00)' },
    {
      key: 'Europe/Zaporozhye',
      value: 'Eastern European Time(EET) (GMT+2:00)',
    },
    { key: 'Antarctica/Syowa', value: 'Syowa Time (GMT+3:00)' },
    { key: 'Asia/Qatar', value: 'Arabia Standard Time (GMT+3:00)' },
    { key: 'Europe/Moscow', value: 'Moscow Standard Time(MSK) (GMT+3:00)' },
    { key: 'Europe/Volgograd', value: 'Volgograd Time (GMT+3:00)' },
    { key: 'Indian/Mayotte', value: 'Eastern African Time (GMT+3:00)' },
    { key: 'Asia/Tehran', value: 'Iran Standard Time (GMT+3:30)' },
    { key: 'Asia/Baku', value: 'Azerbaijan Time (GMT+4:00)' },
    { key: 'Asia/Dubai', value: 'Gulf Standard Time (GMT+4:00)' },
    { key: 'Asia/Tbilisi', value: 'Georgia Time (GMT+4:00)' },
    { key: 'Asia/Yerevan', value: 'Armenia Time (GMT+4:00)' },
    { key: 'Europe/Samara', value: 'Samara Time (GMT+4:00)' },
    { key: 'Indian/Mahe', value: 'Seychelles Time (GMT+4:00)' },
    { key: 'Indian/Mauritius', value: 'Mauritius Time (GMT+4:00)' },
    { key: 'Indian/Reunion', value: 'Reunion Time (GMT+4:00)' },
    { key: 'Asia/Kabul', value: 'Afghanistan Time (GMT+4:30)' },
    { key: 'Asia/Aqtau', value: 'Aqtau Time (GMT+5:00)' },
    { key: 'Asia/Aqtobe', value: 'Aqtobe Time (GMT+5:00)' },
    { key: 'Asia/Ashkhabad', value: 'Turkmenistan Time (GMT+5:00)' },
    { key: 'Asia/Dushanbe', value: 'Tajikistan Time (GMT+5:00)' },
    { key: 'Asia/Karachi', value: 'Pakistan Time (GMT+5:00)' },
    { key: 'Asia/Oral', value: 'Oral Time (GMT+5:00)' },
    { key: 'Asia/Samarkand', value: 'Uzbekistan Time (GMT+5:00)' },
    { key: 'Asia/Yekaterinburg', value: 'Yekaterinburg Time (GMT+5:00)' },
    {
      key: 'Indian/Kerguelen',
      value: 'French Southern & Antarctic Lands Time (GMT+5:00)',
    },
    { key: 'Indian/Maldives', value: 'Maldives Time (GMT+5:00)' },
    { key: 'Asia/Kolkata', value: 'India Standard Time(IST) (GMT+5:30)' },
    { key: 'Asia/Katmandu', value: 'Nepal Time (GMT+5:45)' },
    { key: 'Antarctica/Mawson', value: 'Mawson Time (GMT+6:00)' },
    { key: 'Antarctica/Vostok', value: 'Vostok Time (GMT+6:00)' },
    { key: 'Asia/Almaty', value: 'Alma-Ata Time (GMT+6:00)' },
    { key: 'Asia/Bishkek', value: 'Kirgizstan Time (GMT+6:00)' },
    { key: 'Asia/Dhaka', value: 'Bangladesh Time (GMT+6:00)' },
    { key: 'Asia/Novosibirsk', value: 'Novosibirsk Time (GMT+6:00)' },
    { key: 'Asia/Omsk', value: 'Omsk Time (GMT+6:00)' },
    { key: 'Asia/Qyzylorda', value: 'Qyzylorda Time (GMT+6:00)' },
    { key: 'Asia/Thimphu', value: 'Bhutan Time (GMT+6:00)' },
    { key: 'Indian/Chagos', value: 'Indian Ocean Territory Time (GMT+6:00)' },
    { key: 'Asia/Rangoon', value: 'Myanmar Time (GMT+6:30)' },
    { key: 'Indian/Cocos', value: 'Cocos Islands Time (GMT+6:30)' },
    { key: 'Antarctica/Davis', value: 'Davis Time (GMT+7:00)' },
    { key: 'Asia/Bangkok', value: 'Indochina Time (GMT+7:00)' },
    { key: 'Asia/Hovd', value: 'Hovd Time (GMT+7:00)' },
    { key: 'Asia/Jakarta', value: 'West Indonesia Time (GMT+7:00)' },
    { key: 'Asia/Krasnoyarsk', value: 'Krasnoyarsk Time (GMT+7:00)' },
    { key: 'Indian/Christmas', value: 'Christmas Island Time (GMT+7:00)' },
    { key: 'Asia/Brunei', value: 'Brunei Time (GMT+8:00)' },
    { key: 'Asia/Choibalsan', value: 'Choibalsan Time (GMT+8:00)' },
    { key: 'Asia/Hong_Kong', value: 'Hong Kong Time (GMT+8:00)' },
    { key: 'Asia/Irkutsk', value: 'Irkutsk Time (GMT+8:00)' },
    { key: 'Asia/Kuala_Lumpur', value: 'Malaysia Time (GMT+8:00)' },
    { key: 'Asia/Manila', value: 'Philippines Time (GMT+8:00)' },
    { key: 'Asia/Shanghai', value: 'China Standard Time(CST) (GMT+8:00)' },
    { key: 'Asia/Singapore', value: 'Singapore Time (GMT+8:00)' },
    { key: 'Asia/Ujung_Pandang', value: 'Central Indonesia Time (GMT+8:00)' },
    { key: 'Asia/Ulan_Bator', value: 'Ulaanbaatar Time (GMT+8:00)' },
    {
      key: 'Australia/Perth',
      value: 'Australia Western Standard Time (AWST) (GMT+8:00)',
    },
    {
      key: 'Australia/Eucla',
      value: 'Central Western Standard Time (Australia) (GMT+8:45)',
    },
    { key: 'Asia/Dili', value: 'Timor-Leste Time (GMT+9:00)' },
    { key: 'Asia/Jayapura', value: 'East Indonesia Time (GMT+9:00)' },
    { key: 'Asia/Seoul', value: 'Korea Standard Time (GMT+9:00)' },
    { key: 'Asia/Tokyo', value: 'Japan Standard Time(JST) (GMT+9:00)' },
    { key: 'Asia/Yakutsk', value: 'Yakutsk Time (GMT+9:00)' },
    {
      key: 'Australia/North',
      value: 'Australian Central Standard Time(CST) (GMT+9:30)',
    },
    {
      key: 'Antarctica/DumontDUrville',
      value: "Dumont-d'Urville Time (GMT+10:00)",
    },
    { key: 'Asia/Sakhalin', value: 'Sakhalin Time (GMT+10:00)' },
    { key: 'Asia/Vladivostok', value: 'Vladivostok Time (GMT+10:00)' },
    {
      key: 'Australia/ACT',
      value: 'Australian Eastern Standard Time(AEST) (GMT+10:00)',
    },
    {
      key: 'Australia/Queensland',
      value: 'Australian Eastern Standard Time(AEST) (GMT+10:00)',
    },
    {
      key: 'Australia/Tasmania',
      value: 'Australian Eastern Standard Time(AEST) (GMT+10:00)',
    },
    {
      key: 'Australia/Victoria',
      value: 'Australian Eastern Standard Time(AEST) (GMT+10:00)',
    },
    { key: 'Australia/LHI', value: 'Lord Howe Standard Time (GMT+10:30)' },
    { key: 'Asia/Magadan', value: 'Magadan Time (GMT+11:00)' },
    {
      key: 'Antarctica/South_Pole',
      value: 'New Zealand Standard Time(NZST) (GMT+12:00)',
    },
    { key: 'Asia/Anadyr', value: 'Anadyr Time (GMT+12:00)' },
    {
      key: 'Asia/Kamchatka',
      value: 'Petropavlovsk-Kamchatski Time (GMT+12:00)',
    },
  ],

  gender: [
    { key: 'Male', value: 'Male' },
    { key: 'Female', value: 'Female' },
    { key: 'NonBinary', value: 'Non-Binary' },
    { key: 'Other', value: 'Other' },
  ],

  countryData: [
    { key: 'Afghanistan', value: 'Afghanistan' },
    { key: 'Åland Islands', value: 'Åland Islands' },
    { key: 'Albania', value: 'Albania' },
    { key: 'Algeria', value: 'Algeria' },
    { key: 'American Samoa', value: 'American Samoa' },
    { key: 'Andorra', value: 'Andorra' },
    { key: 'Angola', value: 'Angola' },
    { key: 'Anguilla', value: 'Anguilla' },
    { key: 'Antarctica', value: 'Antarctica' },
    { key: 'Antigua & Barbuda', value: 'Antigua & Barbuda' },
    { key: 'Argentina', value: 'Argentina' },
    { key: 'Armenia', value: 'Armenia' },
    { key: 'Aruba', value: 'Aruba' },
    { key: 'Australia', value: 'Australia' },
    { key: 'Austria', value: 'Austria' },
    { key: 'Azerbaijan', value: 'Azerbaijan' },
    { key: 'Bahamas', value: 'Bahamas' },
    { key: 'Bahrain', value: 'Bahrain' },
    { key: 'Bangladesh', value: 'Bangladesh' },
    { key: 'Barbados', value: 'Barbados' },
    { key: 'Belarus', value: 'Belarus' },
    { key: 'Belgium', value: 'Belgium' },
    { key: 'Belize', value: 'Belize' },
    { key: 'Benin', value: 'Benin' },
    { key: 'Bermuda', value: 'Bermuda' },
    { key: 'Bhutan', value: 'Bhutan' },
    { key: 'Bolivia', value: 'Bolivia' },
    { key: 'Bosnia & Herzegovina', value: 'Bosnia & Herzegovina' },
    { key: 'Botswana', value: 'Botswana' },
    { key: 'Brazil', value: 'Brazil' },
    { key: 'British Indian Ocean Territory', value: 'British Indian Ocean' },
    { key: 'British Virgin Islands', value: 'British Virgin Islands' },
    { key: 'Brunei', value: 'Brunei' },
    { key: 'Bulgaria', value: 'Bulgaria' },
    { key: 'Burkina Faso', value: 'Burkina Faso' },
    { key: 'Burundi', value: 'Burundi' },
    { key: 'Cambodia', value: 'Cambodia' },
    { key: 'Cameroon', value: 'Cameroon' },
    { key: 'Canada', value: 'Canada' },
    { key: 'Cape Verde', value: 'Cape Verde' },
    { key: 'Cayman Islands', value: 'Cayman Islands' },
    { key: 'Central African Republic', value: 'Central African Republic' },
    { key: 'Chad', value: 'Chad' },
    { key: 'Chile', value: 'Chile' },
    { key: 'China', value: 'China' },
    { key: 'Christmas Island', value: 'Christmas Island' },
    { key: 'Cocos (Keeling) Islands', value: 'Cocos (Keeling) Islands' },
    { key: 'Colombia', value: 'Colombia' },
    { key: 'Comoros', value: 'Comoros' },
    { key: 'Congo - Brazzaville', value: 'Congo - Brazzaville' },
    { key: 'Congo - Kinshasa', value: 'Congo - Kinshasa' },
    { key: 'Cook Islands', value: 'Cook Islands' },
    { key: 'Costa Rica', value: 'Costa Rica' },
    { key: 'Côte d’Ivoire', value: 'Côte d’Ivoire' },
    { key: 'Croatia', value: 'Croatia' },
    { key: 'Cuba', value: 'Cuba' },
    { key: 'Curaçao', value: 'Curaçao' },
    { key: 'Cyprus', value: 'Cyprus' },
    { key: 'Czechia', value: 'Czechia' },
    { key: 'Denmark', value: 'Denmark' },
    { key: 'Djibouti', value: 'Djibouti' },
    { key: 'Dominica', value: 'Dominica' },
    { key: 'Dominican Republic', value: 'Dominican Republic' },
    { key: 'Ecuador', value: 'Ecuador' },
    { key: 'Egypt', value: 'Egypt' },
    { key: 'El Salvador', value: 'El Salvador' },
    { key: 'Equatorial Guinea', value: 'Equatorial Guinea' },
    { key: 'Eritrea', value: 'Eritrea' },
    { key: 'Estonia', value: 'Estonia' },
    { key: 'Ethiopia', value: 'Ethiopia' },
    { key: 'Falkland Islands', value: 'Falkland Islands' },
    { key: 'Faroe Islands', value: 'Faroe Islands' },
    { key: 'Fiji', value: 'Fiji' },
    { key: 'Finland', value: 'Finland' },
    { key: 'France', value: 'France' },
    { key: 'French Guiana', value: 'French Guiana' },
    { key: 'French Polynesia', value: 'French Polynesia' },
    { key: 'French Southern Territories', value: 'French Southern' },
    { key: 'Gabon', value: 'Gabon' },
    { key: 'Gambia', value: 'Gambia' },
    { key: 'Georgia', value: 'Georgia' },
    { key: 'Germany', value: 'Germany' },
    { key: 'Ghana', value: 'Ghana' },
    { key: 'Gibraltar', value: 'Gibraltar' },
    { key: 'Greece', value: 'Greece' },
    { key: 'Greenland', value: 'Greenland' },
    { key: 'Grenada', value: 'Grenada' },
    { key: 'Guadeloupe', value: 'Guadeloupe' },
    { key: 'Guam', value: 'Guam' },
    { key: 'Guatemala', value: 'Guatemala' },
    { key: 'Guernsey', value: 'Guernsey' },
    { key: 'Guinea', value: 'Guinea' },
    { key: 'Guinea-Bissau', value: 'Guinea-Bissau' },
    { key: 'Guyana', value: 'Guyana' },
    { key: 'Haiti', value: 'Haiti' },
    { key: 'Honduras', value: 'Honduras' },
    { key: 'Hong Kong SAR China', value: 'Hong Kong SAR China' },
    { key: 'Hungary', value: 'Hungary' },
    { key: 'Iceland', value: 'Iceland' },
    { key: 'India', value: 'India' },
    { key: 'Indonesia', value: 'Indonesia' },
    { key: 'Iran', value: 'Iran' },
    { key: 'Iraq', value: 'Iraq' },
    { key: 'Ireland', value: 'Ireland' },
    { key: 'Isle of Man', value: 'Isle of Man' },
    { key: 'Israel', value: 'Israel' },
    { key: 'Italy', value: 'Italy' },
    { key: 'Jamaica', value: 'Jamaica' },
    { key: 'Japan', value: 'Japan' },
    { key: 'Jersey', value: 'Jersey' },
    { key: 'Jordan', value: 'Jordan' },
    { key: 'Kazakhstan', value: 'Kazakhstan' },
    { key: 'Kenya', value: 'Kenya' },
    { key: 'Kiribati', value: 'Kiribati' },
    { key: 'Kosovo', value: 'Kosovo' },
    { key: 'Kuwait', value: 'Kuwait' },
    { key: 'Kyrgyzstan', value: 'Kyrgyzstan' },
    { key: 'Laos', value: 'Laos' },
    { key: 'Latvia', value: 'Latvia' },
    { key: 'Lebanon', value: 'Lebanon' },
    { key: 'Lesotho', value: 'Lesotho' },
    { key: 'Liberia', value: 'Liberia' },
    { key: 'Libya', value: 'Libya' },
    { key: 'Liechtenstein', value: 'Liechtenstein' },
    { key: 'Lithuania', value: 'Lithuania' },
    { key: 'Luxembourg', value: 'Luxembourg' },
    { key: 'Macau SAR China', value: 'Macau SAR China' },
    { key: 'Macedonia', value: 'Macedonia' },
    { key: 'Madagascar', value: 'Madagascar' },
    { key: 'Malawi', value: 'Malawi' },
    { key: 'Malaysia', value: 'Malaysia' },
    { key: 'Maldives', value: 'Maldives' },
    { key: 'Mali', value: 'Mali' },
    { key: 'Malta', value: 'Malta' },
    { key: 'Marshall Islands', value: 'Marshall Islands' },
    { key: 'Martinique', value: 'Martinique' },
    { key: 'Mauritania', value: 'Mauritania' },
    { key: 'Mauritius', value: 'Mauritius' },
    { key: 'Mayotte', value: 'Mayotte' },
    { key: 'Mexico', value: 'Mexico' },
    { key: 'Micronesia', value: 'Micronesia' },
    { key: 'Moldova', value: 'Moldova' },
    { key: 'Monaco', value: 'Monaco' },
    { key: 'Mongolia', value: 'Mongolia' },
    { key: 'Montenegro', value: 'Montenegro' },
    { key: 'Montserrat', value: 'Montserrat' },
    { key: 'Morocco', value: 'Morocco' },
    { key: 'Mozambique', value: 'Mozambique' },
    { key: 'Myanmar (Burma)', value: 'Myanmar (Burma)' },
    { key: 'Namibia', value: 'Namibia' },
    { key: 'Nauru', value: 'Nauru' },
    { key: 'Nepal', value: 'Nepal' },
    { key: 'Netherlands', value: 'Netherlands' },
    { key: 'New Caledonia', value: 'New Caledonia' },
    { key: 'New Zealand', value: 'New Zealand' },
    { key: 'Nicaragua', value: 'Nicaragua' },
    { key: 'Niger', value: 'Niger' },
    { key: 'Nigeria', value: 'Nigeria' },
    { key: 'Niue', value: 'Niue' },
    { key: 'Norfolk Island', value: 'Norfolk Island' },
    { key: 'North Korea', value: 'North Korea' },
    { key: 'Northern Mariana Islands', value: 'Northern Mariana Islands' },
    { key: 'Norway', value: 'Norway' },
    { key: 'Oman', value: 'Oman' },
    { key: 'Pakistan', value: 'Pakistan' },
    { key: 'Palau', value: 'Palau' },
    { key: 'Palestinian Territories', value: 'Palestinian Territories' },
    { key: 'Panama', value: 'Panama' },
    { key: 'Papua New Guinea', value: 'Papua New Guinea' },
    { key: 'Paraguay', value: 'Paraguay' },
    { key: 'Peru', value: 'Peru' },
    { key: 'Philippines', value: 'Philippines' },
    { key: 'Pitcairn Islands', value: 'Pitcairn Islands' },
    { key: 'Poland', value: 'Poland' },
    { key: 'Portugal', value: 'Portugal' },
    { key: 'Puerto Rico', value: 'Puerto Rico' },
    { key: 'Qatar', value: 'Qatar' },
    { key: 'Réunion', value: 'Réunion' },
    { key: 'Romania', value: 'Romania' },
    { key: 'Russia', value: 'Russia' },
    { key: 'Rwanda', value: 'Rwanda' },
    { key: 'Samoa', value: 'Samoa' },
    { key: 'San Marino', value: 'San Marino' },
    { key: 'São Tomé & Príncipe', value: 'São Tomé & Príncipe' },
    { key: 'Saudi Arabia', value: 'Saudi Arabia' },
    { key: 'Senegal', value: 'Senegal' },
    { key: 'Serbia', value: 'Serbia' },
    { key: 'Seychelles', value: 'Seychelles' },
    { key: 'Sierra Leone', value: 'Sierra Leone' },
    { key: 'Singapore', value: 'Singapore' },
    { key: 'Sint Maarten', value: 'Sint Maarten' },
    { key: 'Slovakia', value: 'Slovakia' },
    { key: 'Slovenia', value: 'Slovenia' },
    { key: 'Solomon Islands', value: 'Solomon Islands' },
    { key: 'Somalia', value: 'Somalia' },
    { key: 'South Africa', value: 'South Africa' },
    {
      key: 'South Georgia & South Sandwich Islands',
      value: 'South Georgia & South Sandwich Islands',
    },
    { key: 'South Korea', value: 'South Korea' },
    { key: 'South Sudan', value: 'South Sudan' },
    { key: 'Spain', value: 'Spain' },
    { key: 'Sri Lanka', value: 'Sri Lanka' },
    { key: 'St. Barthélemy', value: 'St. Barthélemy' },
    { key: 'St. Helena', value: 'St. Helena' },
    { key: 'St. Kitts & Nevis', value: 'St. Kitts & Nevis' },
    { key: 'St. Lucia', value: 'St. Lucia' },
    { key: 'St. Martin', value: 'St. Martin' },
    { key: 'St. Pierre & Miquelon', value: 'St. Pierre & Miquelon' },
    { key: 'St. Vincent & Grenadines', value: 'St. Vincent & Grenadines' },
    { key: 'Sudan', value: 'Sudan' },
    { key: 'Suriname', value: 'Suriname' },
    { key: 'Svalbard & Jan Mayen', value: 'Svalbard & Jan Mayen' },
    { key: 'Swaziland', value: 'Swaziland' },
    { key: 'Sweden', value: 'Sweden' },
    { key: 'Switzerland', value: 'Switzerland' },
    { key: 'Syria', value: 'Syria' },
    { key: 'Taiwan', value: 'Taiwan' },
    { key: 'Tajikistan', value: 'Tajikistan' },
    { key: 'Tanzania', value: 'Tanzania' },
    { key: 'Thailand', value: 'Thailand' },
    { key: 'Timor-Leste', value: 'Timor-Leste' },
    { key: 'Togo', value: 'Togo' },
    { key: 'Tokelau', value: 'Tokelau' },
    { key: 'Tonga', value: 'Tonga' },
    { key: 'Trinidad & Tobago', value: 'Trinidad & Tobago' },
    { key: 'Tristan da Cunha', value: 'Tristan da Cunha' },
    { key: 'Tunisia', value: 'Tunisia' },
    { key: 'Turkey', value: 'Turkey' },
    { key: 'Turkmenistan', value: 'Turkmenistan' },
    { key: 'Turks & Caicos Islands', value: 'Turks & Caicos Islands' },
    { key: 'Tuvalu', value: 'Tuvalu' },
    { key: 'U.S. Outlying Islands', value: 'U.S. Outlying Islands' },
    { key: 'U.S. Virgin Islands', value: 'U.S. Virgin Islands' },
    { key: 'Uganda', value: 'Uganda' },
    { key: 'Ukraine', value: 'Ukraine' },
    { key: 'United Arab Emirates', value: 'United Arab Emirates' },
    { key: 'United Kingdom', value: 'United Kingdom' },
    { key: 'United States', value: 'United States' },
    { key: 'Uruguay', value: 'Uruguay' },
    { key: 'Uzbekistan', value: 'Uzbekistan' },
    { key: 'Vanuatu', value: 'Vanuatu' },
    { key: 'Vatican City', value: 'Vatican City' },
    { key: 'Venezuela', value: 'Venezuela' },
    { key: 'Vietnam', value: 'Vietnam' },
    { key: 'Wallis & Futuna', value: 'Wallis & Futuna' },
    { key: 'Western Sahara', value: 'Western Sahara' },
    { key: 'Yemen', value: 'Yemen' },
    { key: 'Zambia', value: 'Zambia' },
    { key: 'Zimbabwe', value: 'Zimbabwe' },
  ],

  stateData: [
    { key: 'Alabama', value: 'Alabama' },
    { key: 'Alaska', value: 'Alaska' },
    { key: 'Arizona', value: 'Arizona' },
    { key: 'Arkansas', value: 'Arkansas' },
    { key: 'California', value: 'California' },
    { key: 'Colorado', value: 'Colorado' },
    { key: 'Connecticut', value: 'Connecticut' },
    { key: 'Delaware', value: 'Delaware' },
    { key: 'Florida', value: 'Florida' },
    { key: 'Georgia', value: 'Georgia' },
    { key: 'Hawaii', value: 'Hawaii' },
    { key: 'Idaho', value: 'Idaho' },
    { key: 'Illinois', value: 'Illinois' },
    { key: 'Indiana', value: 'Indiana' },
    { key: 'Iowa', value: 'Iowa' },
    { key: 'Kansas', value: 'Kansas' },
    { key: 'Kentucky', value: 'Kentucky' },
    { key: 'Louisiana', value: 'Louisiana' },
    { key: 'Maine', value: 'Maine' },
    { key: 'Maryland', value: 'Maryland' },
    { key: 'Massachusetts', value: 'Massachusetts' },
    { key: 'Michigan', value: 'Michigan' },
    { key: 'Minnesota', value: 'Minnesota' },
    { key: 'Mississippi', value: 'Mississippi' },
    { key: 'Missouri', value: 'Missouri' },
    { key: 'Montana', value: 'Montana' },
    { key: 'Nebraska', value: 'Nebraska' },
    { key: 'Nevada', value: 'Nevada' },
    { key: 'Hampshire', value: 'Hampshire' },
    { key: 'Jersey', value: 'Jersey' },
    { key: 'Mexico', value: 'Mexico' },
    { key: 'York', value: 'York' },
    { key: 'Ohio', value: 'Ohio' },
    { key: 'Oklahoma', value: 'Oklahoma' },
    { key: 'Oregon', value: 'Oregon' },
    { key: 'Pennsylvania', value: 'Pennsylvania' },
    { key: 'Island', value: 'Island' },
    { key: 'Carolina', value: 'Carolina' },
    { key: 'Dakota', value: 'Dakota' },
    { key: 'Tennessee', value: 'Tennessee' },
    { key: 'Texas', value: 'Texas' },
    { key: 'Utah', value: 'Utah' },
    { key: 'Vermont', value: 'Vermont' },
    { key: 'Virginia', value: 'Virginia' },
    { key: 'Washington', value: 'Washington' },
    { key: 'Wisconsin', value: 'Wisconsin' },
  ],
  coachSpecialityList: [
    'Nonprofits',
    'Students',
    'Millennial founders',
    'Female early stage founders',
    'Female high growth founders',
    'Elite client',
    'Power founder',
  ],

  ethnicities: [
    'African, African-American',
    'Chinese, Chinese-American',
    'Caucasian, European, European-American',
    'Filipino, Filipino-American',
    'Japanese, Japanese-American',
    'Korean, Korean-American',
    'Southeast Asian (e.g., Vietnam, Cambodia, etc.)',
    'Other Asian',
    'Mexican, Mexican-American, Chicano',
    'Other Latino',
    'Middle Eastern (e.g., Arab, Persian)',
    'Native American, First Nation',
  ],
  languages: [
    'Afrikaans',
    'Albanian',
    'Arabic',
    'Armenian',
    'Basque',
    'Bengali',
    'Bulgarian',
    'Catalan',
    'Cambodian',
    'Chinese (Mandarin)',
    'Croatian',
    'Czech',
    'Danish',
    'Dutch',
    'English',
    'Estonian',
    'Fiji',
    'Finnish',
    'French',
    'Georgian',
    'German',
    'Greek',
    'Gujarati',
    'Hebrew',
    'Hindi',
    'Hungarian',
    'Icelandic',
    'Indonesian',
    'Irish',
    'Italian',
    'Japanese',
    'Javanese',
    'Korean',
    'Latin',
    'Latvian',
    'Lithuanian',
    'Macedonian',
    'Malay',
    'Malayalam',
    'Maltese',
    'Maori',
    'Marathi',
    'Mongolian',
    'Nepali',
    'Norwegian',
    'Persian',
    'Polish',
    'Portuguese',
    'Punjabi',
    'Quechua',
    'Romanian',
    'Russian',
    'Samoan',
    'Serbian',
    'Slovak',
    'Slovenian',
    'Spanish',
    'Swahili',
    'Swedish ',
    'Tamil',
    'Tatar',
    'Telugu',
    'Thai',
    'Tibetan',
    'Tonga',
    'Turkish',
    'Ukrainian',
    'Urdu',
    'Uzbek',
    'Vietnamese',
    'Welsh',
    'Xhos',
  ],
  genders: ['Male', 'Female', 'Non-Binary', 'Other'],
  countries: countriesData,
  ethnicity: {
    'African, African-American': 'African',
    'Chinese, Chinese-American': 'Asian',
    'Caucasian, European, European-American': 'Caucasian',
    'Filipino, Filipino-American': 'Asian',
    'Japanese, Japanese-American': 'Asian',
    'Korean, Korean-American': 'Asian',
    'Southeast Asian (e.g., Vietnam, Cambodia, etc.)': 'Asian',
    'Other Asian': 'Asian',
    'Mexican, Mexican-American, Chicano': 'Latino',
    'Other Latino': 'Latino',
    'Middle Eastern (e.g., Arab, Persian)': 'Middle Eastern',
    'Native American, First Nation': 'Native American',
  },
  education: [
    'No Schooling Completed',
    'Nursery School to 8th Grade',
    'Some High School',
    'High School Graduate or Equivalent',
    'Some College',
    'Trade, Technical, or Vocational',
    "Associate's Degree",
    "Bachelor's Degree",
    "Master's Degree",
    'Professional Degree',
    'Doctorate Degree',
  ],
  growthList: [
    'Wellbeing: Stress and Anxiety Management',
    'Executive Presence and Leadership',
    'Team Cohesion and Culture',
    'Purpose and Life Alignment',
    'Emotional Intelligence and Authentic Communication',
    'Practical Manager Skills',
    'Interpersonal or Co-Founder Relationships',
    'Role Transitioning',
    'Organizational Development',
    'Abroad Travel & Experiential Learning',
  ],
  userStatus: ['I am in career transition', 'I am currenly in operating role'],
  roleList: [
    'C-Suite/Founder/Managing Partner',
    'Senior Vice President/Partner',
    'Vice President/Principal',
    'Managing Partner/Director',
    'Partner',
    'Senior Director',
    'Director',
    'Department Head/Manager',
    'Team Member/Associate',
    'Principal',
    'Student',
    'No Titles or Hierarchy',
    'Our organization has no titles or hierarchy',
  ],
  companyType: [
    'Large Enterprise/Corporation',
    'Small-to-Medium-Sized Enterprise (SME)',
    'Small Business',
    'Non-Profit/NGO',
    'High-Growth Startup',
    'Family Office',
    'Venture Capital/PE Fund',
    'Hedge Fund',
  ],
  companyPeople: [
    'Self-employed',
    '1-10 employees',
    '11-50 employees',
    '51-200 employees',
    '201-500 employees',
    '501-1,000 employees',
    '1,001-5,000 employees',
    '5,001-10,000 employees',
    '10,001+ employees',
  ],
  // investmentStage: [
  //   'Not Applicable',
  //   'Ideation Stage',
  //   'Self-Funded - "Our team has financed most of it."',
  //   'Friends and Family - "Most of our funding has come from personal contacts."',
  //   'Crowdfunding - "The masses have spoken."',
  //   'Angel Investment - "An individual or individuals have kickstarted our company."',
  //   'Accelerator - "We\'re part of an organization providing a little capital, a lot of mentoring, and some office space."',
  //   'Seed Investment - "We\'ve secured between $500k -$1 million from investors."',
  //   'Series A - "We\'ve secured between $1-6 million from investors."',
  //   'Series B - "We\'ve secured between $6.1-11 million from investors."',
  //   'Series C - "We\'ve secured between $11.1-100 million from investors."',
  //   'Series D and Beyond - "We want to see how far the rabbit hole goes."',
  //   'Publicly Traded Company',
  // ],
  plans: {
    free: {
      name: 'FREE',
      sequence: 0,
      license: 1,
      price: 0.0,
      duration: 42, // in days
    },
    growth: {
      name: 'GROWTH',
      sequence: 1,
      license: 1,
      price: 250.0,
      duration: 42,
    },
    breakthrough: {
      name: 'BREAKTHROUGH',
      sequence: 2,
      license: 1,
      price: 2500.0, // todo
      duration: 365,
    },
  },
  organizationType: [
    'Large Enterprise/Corporation',
    'Small-to-Medium Sized Enterprise (SME)',
    'Small Business',
    'Non-Profit/NGO',
    'High Growth Startup',
    'Family Office',
    'Venture Capital/PE Fund',
    'Hedge Fund',
  ],
  organizationPeople: [
    'Self-employed',
    '1-10 employees',
    '11-50 employees',
    '51-200 employees',
    '201-500 employees',
    '501-1000 employees',
    '1001-5000 employees',
    '5001-10,000 employees',
    '10,001+ employees',
  ],
  investmentStage: [
    'Not Applicable',
    "Ideation Stage - we're getting our vision in order.",
    'Self Funded - our team has financed most of it.',
    'Friends & Family - most of our funding has come from personal contacts.',
    'Crowdfunding - the masses have spoken.',
    'Angel Investment - an individual or individuals have kickstarted our company.',
    "Accelerator - we're part of an organization providing a little capital, a lot of mentoring, and some office space.",
    "Seed Investment - we've secured between $500k -$1 million from investors.",
    "Series A - we've secured between $1-6 million from investors.",
    "Series B - we've secured between $6.1-11 million from investors.",
    "Series C - we've secured between $11.1-100 million from investors.",
    'Series D & Beyond - we want to see how far the rabbit hole goes.',
    'Publicly traded company',
  ],
}

export default labels
