"use strict";

var _interopRequireDefault = require("D:/Code/abroad-client/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _classCallCheck2 = _interopRequireDefault(require("D:/Code/abroad-client/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/classCallCheck"));

var _RestClient = _interopRequireDefault(require("./RestClient"));

var _mediaLibrary = _interopRequireDefault(require("./admin/mediaLibrary"));

var _user = _interopRequireDefault(require("./admin/user"));

var _home = _interopRequireDefault(require("./admin/home"));

var _cohort = _interopRequireDefault(require("./admin/cohort"));

var _company = _interopRequireDefault(require("./admin/company"));

var _account = _interopRequireDefault(require("./admin/account"));

var _Analytics = _interopRequireDefault(require("./admin/Analytics"));

var _sitecopy = _interopRequireDefault(require("./admin/sitecopy"));

var _teams = _interopRequireDefault(require("./admin/teams"));

var _coachingPlan = _interopRequireDefault(require("./admin/coachingPlan"));

var _coachList = _interopRequireDefault(require("./admin/coachList"));

var _clientsList = _interopRequireDefault(require("./admin/clientsList"));

var _coachClient = _interopRequireDefault(require("./admin/coachClient"));

var _coachPlan = _interopRequireDefault(require("./admin/coachPlan"));

var Admin = function Admin() {
  var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  (0, _classCallCheck2["default"])(this, Admin);
  var restClient = new _RestClient["default"]({
    baseUrl: options.baseUrl
  });
  this.mediaLibrary = new _mediaLibrary["default"](restClient);
  this.userList = new _user["default"](restClient);
  this.companyList = new _company["default"](restClient);
  this.home = new _home["default"](restClient);
  this.cohort = new _cohort["default"](restClient);
  this.account = new _account["default"](restClient);
  this.siteCopy = new _sitecopy["default"](restClient);
  this.analytics = new _Analytics["default"](restClient);
  this.teams = new _teams["default"](restClient);
  this.coachingPlan = new _coachingPlan["default"](restClient);
  this.coachingList = new _coachList["default"](restClient);
  this.clientsList = new _clientsList["default"](restClient);
  this.coachClient = new _coachClient["default"](restClient);
  this.coachPlan = new _coachPlan["default"](restClient);
};

exports["default"] = Admin;