import React from 'react'
import { Form } from 'react-bootstrap'
import { useField } from 'formik'
import classnames from 'classnames'

const Checkbox = ({
  formGroupProps,
  labelProps: { className: labelClassName, ...other } = {},
  label,
  custom,
  ...rest
}) => {
  const [field] = useField(rest)
  return (
    <Form.Group {...formGroupProps}>
      <Form.Check
        id={`custom-${rest?.name}`}
        custom={custom}
        type={'checkbox'}
        className={classnames('cursor-pointer align-baseline', rest?.className)}
        {...field}
        {...rest}>
        <Form.Check.Input
          type={'checkbox'}
          className='z3'
          {...field}
          {...rest}
        />
        <Form.Check.Label
          className={classnames('font-14', labelClassName)}
          {...other}>
          {label}
        </Form.Check.Label>
      </Form.Check>
    </Form.Group>
  )
}

Checkbox.defaultProps = {
  custom: true,
}

export default Checkbox
