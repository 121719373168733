import React, { useEffect, useState } from 'react'
import { Form as RBForm, Row, Col } from 'react-bootstrap'
import { useErrorService, ABSpinner } from '@abroad/components'
import { Formik, Form } from 'formik'
import { Trans, withTranslation } from 'react-i18next'
import { string, number, object } from 'yup'
import { InputField } from '../form'
import CustomSelectionField from './CustomSelectionField'
import PriorityField from './PriorityField'
import SelectionField from './SelectionField'
import CurrentStatusField from './CurrentStatusField'
import { isLGScreen } from '../../utils/utility'
import Labels from '../../constants/labels'
import API from '../../utils/API'

const labelProps = {
  column: isLGScreen ? true : false,
  sm: '2',
}

const formInitialValues = {
  gender: '',
  ethnicity: [],
  workStatus: '',
  companyName: '',
  affiliation: '',
  age: '',
  virtualWorkers: '',
  cohorts: [],
}

const validationSchema = object().shape({
  country: string().required(),
  ethnicity: string().required(),
  gender: string().required(),
  affiliation: string().required(),
  age: number().min(10).max(99).integer().required(),
  education: string().required(),
  coachingPriority1: string().required(),
  coachingPriority2: string().required(),
  coachingPriority3: string().required(),
  companyName: string().when('workStatus', {
    is: Labels.userStatus[0],
    then: string().notRequired(),
    otherwise: () => string().required(),
  }),
  roleInCompany: string().when('workStatus', {
    is: Labels.userStatus[0],
    then: string().notRequired(),
    otherwise: () => string().required(),
  }),
  companyType: string().when('workStatus', {
    is: Labels.userStatus[0],
    then: string().notRequired(),
    otherwise: () => string().required(),
  }),
  numberOfPeopleInCompany: string().when('workStatus', {
    is: Labels.userStatus[0],
    then: string().notRequired(),
    otherwise: () => string().required(),
  }),
  investmentStage: string().when('workStatus', {
    is: Labels.userStatus[0],
    then: string().notRequired(),
    otherwise: () => string().required(),
  }),
  virtualWorkers: number().when('workStatus', {
    is: Labels.userStatus[0],
    then: number().notRequired(),
    otherwise: () => number().required(),
  }),
})

const PersonalInformation = ({ clientId }) => {
  const [initialValues, setInitialValues] = useState(formInitialValues)
  const [isLoading, setIsLoading] = useState(true)
  const Error = useErrorService()

  useEffect(() => {
    const getProfile = async () => {
      try {
        const { data } = await API.ClientAPI.user.getProfile(clientId)
        setInitialValues((prev) => ({
          ...prev,
          gender: data.profile?.gender ? data.profile?.gender : '',
          age: data.profile?.age ? data.profile?.age : '',
          country: data.profile?.country ? data.profile?.country : '',
          ethnicity:
            data.profile?.ethnicity?.length > 0
              ? data.profile?.ethnicity?.map((ethnicity) => ({
                  value: ethnicity,
                  label: ethnicity,
                }))
              : [],
          cohorts:
            data?.cohorts?.length > 0
              ? data?.cohorts?.map((cohort) => ({
                  value: cohort._id,
                  label: cohort.name,
                }))
              : [],
          education: data.profile?.education ? data.profile?.education : '',
          coachingPriority1: data.profile?.coachingPriority1
            ? data.profile?.coachingPriority1
            : '',
          coachingPriority2: data.profile?.coachingPriority2
            ? data.profile?.coachingPriority2
            : '',
          coachingPriority3: data.profile?.coachingPriority3
            ? data.profile?.coachingPriority3
            : '',
          roleInCompany: data.profile?.roleInCompany
            ? data.profile?.roleInCompany
            : '',

          companyType: data.profile?.companyType
            ? data.profile?.companyType
            : '',
          numberOfPeopleInCompany: data.profile?.numberOfPeopleInCompany
            ? data.profile?.numberOfPeopleInCompany
            : '',
          investmentStage: data.profile?.investmentStage
            ? data.profile?.investmentStage
            : '',
          virtualWorkers: data.profile?.virtualWorkers
            ? data.profile?.virtualWorkers
            : '',
          workStatus:
            data?.workStatus && data.workStatus === 'operating'
              ? Labels.userStatus[1]
              : Labels.userStatus[0],
          companyName: data?.company ? data?.company : '',
          affiliation:
            data.affiliation.length > 0
              ? data.affiliation.join(', ')
              : 'Not Specified',
        }))
        setIsLoading(false)
      } catch (e) {
        setIsLoading(false)
        Error.showError(e)
      }
    }
    getProfile()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isLoading) {
    return <ABSpinner />
  }

  return (
    <Formik
      displayName='personal-info-form'
      validationSchema={validationSchema}
      initialValues={initialValues}
      enableReinitialize={true}
      validateOnMount
      onSubmit={() => {}}>
      {({ values, handleSubmit }) => {
        return (
          <RBForm
            onSubmit={handleSubmit}
            as={Form}
            className='profile px-4 px-lg-0'>
            <Row noGutters className='mb-3'>
              <Col className='col-sm-2 d-none d-lg-block' />
              <Col className='col-lg-offset-2'>
                <h3>
                  <Trans>Personal Information</Trans>
                </h3>
              </Col>
            </Row>
            {/* COHORTS */}
            {values?.cohorts.length !== 0 ? (
              <CustomSelectionField
                name='cohorts'
                placeholder='Select'
                label={`USER COHORTS`}
                options={values?.cohorts.map((data) => ({
                  value: data?._id,
                  label: data?.name,
                }))}
                disabled
              />
            ) : labelProps?.column === true ? (
              <div class='form-group'>
                <span class='d-flex position-relative align-items-center'>
                  <div class='d-lg-flex align-items-center w-100'>
                    <label class='form-label col-form-label col-sm-2'>
                      COHORTS
                    </label>
                    No Cohorts.
                  </div>
                </span>
              </div>
            ) : (
              <div class='form-group form-component-wrp d-flex align-items-baseline'>
                <label class='form-label text-right font-14'>COHORTS</label>
                <div class='custom-templates' id='cohort-text'>
                  No Cohorts
                </div>
              </div>
            )}
            <SelectionField
              label='Gender'
              labelProps={labelProps}
              name='gender'
              options={Labels.genders}
              disabled
            />
            <InputField
              label='Age'
              labelProps={labelProps}
              name='age'
              type='number'
              size='lg'
              placeholder='Age'
              sm='10'
              disabled
            />
            <SelectionField
              label='Country'
              labelProps={labelProps}
              name='country'
              options={Object.keys(Labels.countries)}
              disabled
            />
            <CustomSelectionField
              name='ethnicity'
              placeholder='Select'
              label='ETHNICITY / RACE'
              options={Object.keys(Labels.ethnicity).map((key) => ({
                value: key,
                label: key,
              }))}
              disabled
            />
            <SelectionField
              label='EDUCATION'
              labelProps={labelProps}
              name='education'
              options={Labels.education}
              disabled
            />
            <PriorityField
              label='FIRST PRIORITY'
              labelProps={labelProps}
              name='coachingPriority1'
              disabledOptions={[
                values?.coachingPriority2,
                values?.coachingPriority3,
              ]}
              disabled
            />
            <PriorityField
              label='SECOND PRIORITY'
              labelProps={labelProps}
              name='coachingPriority2'
              disabledOptions={[
                values?.coachingPriority1,
                values?.coachingPriority3,
              ]}
              disabled
            />
            <PriorityField
              label='THIRD PRIORITY'
              labelProps={labelProps}
              name='coachingPriority3'
              disabledOptions={[
                values?.coachingPriority1,
                values?.coachingPriority2,
              ]}
              disabled
            />
            <Row noGutters className='py-3'>
              <Col className='col-sm-2 d-none d-lg-block' />
              <Col className=''>
                <h3>
                  <Trans>Organization Information</Trans>
                </h3>
              </Col>
            </Row>
            <CurrentStatusField labelProps={labelProps} disabled />
            <InputField
              label='COMPANY'
              labelProps={labelProps}
              name='companyName'
              type='text'
              placeholder='Company'
              size='lg'
              sm='10'
              disabled
            />
            <InputField
              label='AFFILIATION'
              labelProps={labelProps}
              name='affiliation'
              type='text'
              placeholder='Affiliation'
              size='lg'
              sm='10'
              disabled
            />
            <SelectionField
              label='ROLE AT COMPANY'
              labelProps={labelProps}
              name='roleInCompany'
              options={Labels.roleList}
              disabled
            />
            <SelectionField
              label='COMPANY TYPE'
              labelProps={labelProps}
              name='companyType'
              options={Labels.companyType}
              disabled
            />
            <SelectionField
              label='NO. OF PEOPLE'
              labelProps={labelProps}
              name='numberOfPeopleInCompany'
              options={Labels.companyPeople}
              disabled
            />
            <SelectionField
              label='INVESTMENT STAGE'
              labelProps={labelProps}
              name='investmentStage'
              options={Labels.investmentStage}
              disabled
            />
            <InputField
              label='% OF VIRTUAL WORKERS'
              labelProps={labelProps}
              name='virtualWorkers'
              type='number'
              min='0'
              max='100'
              placeholder='Virtual Workers'
              size='lg'
              sm='10'
              disabled
            />
          </RBForm>
        )
      }}
    </Formik>
  )
}

export default withTranslation('translation')(PersonalInformation)
