import { useErrorService, ABSpinner, useUserService } from '@abroad/components'
import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { NavLink, useParams } from 'react-router-dom'
import classnames from 'classnames'
import moment from 'moment'
import API from '../utils/API'
import {
  PersonalInformation,
  SessionList,
  Reports,
} from '../components/clientProfile'
import { formatDate } from '../utils/date'

const ClientProfile = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [clientProfile, setClientProfile] = useState(null)
  const [timeline, setTimeline] = useState({})
  const [surveyList, setSurveyList] = useState([])
  const [clientSurveyList, setClientSurveyList] = useState([])
  const [planExpiryLoader, setPlanExpiryLoader] = useState(false)
  const [expiresOn, setExpiresOn] = useState('')
  const Error = useErrorService()

  let { clientId } = useParams()
  const { user } = useUserService()
  const hasPlan = true

  useEffect(() => {
    const fetchClientProfile = async () => {
      try {
        setIsLoading(true)
        const { data } = await API.CoachAPI.coachClient.getClientProfile(
          clientId,
        )
        setClientProfile(data)
        setExpiresOn(data.expiresOn)
      } catch (e) {
        Error.showError(e)
        setIsLoading(false)
      }
    }
    const fetchClientTimeline = async () => {
      try {
        setIsLoading(true)
        const { data } = await API.CoachAPI.coachUser.getUserTimeline(clientId)
        setTimeline(data)
      } catch (e) {
        Error.showError(e)
        setIsLoading(false)
      }
    }
    const fetchClientSurveyList = async () => {
      try {
        const { data } = await API.CoachAPI.coachClient.getClientSurveyList(
          clientId,
        )
        setClientSurveyList(data)
        setIsLoading(false)
      } catch (e) {
        Error.showError(e)
        setIsLoading(false)
      }
    }
    fetchClientProfile()
    fetchClientTimeline()
    fetchClientSurveyList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const compare = () => {
    window.open(
      `/survey/comparison_report?survey=${surveyList[0]}&survey=${surveyList[1]}`,
    )
  }

  const updatePlanExpiry = async (value) => {
    try {
      setPlanExpiryLoader(true)
      const { data } = await API.CoachAPI.coachUser.updatePlanExpiry(clientId, {
        expiresOn: value,
      })
      setExpiresOn(data.expiresOn)
      setPlanExpiryLoader(false)
    } catch (e) {
      setPlanExpiryLoader(false)
      Error.showError(e)
    }
  }

  const viewOnly = !(user.admin || user.isAdmin)

  if (isLoading || !clientProfile) {
    return <ABSpinner />
  }

  return (
    <>
      <div className='user-show-title-wrp'>
        <div className='container'>
          {/* <div className="alert alert-success" style="display: none" role="alert">Plan end date updated.</div> */}
          <div className='user-show-title mob-flex-center'>
            <h3>
              {clientProfile.fname} {clientProfile.lname}
            </h3>
            <div>
              {/* todo */}
              {/* {!user.admin && (
                <button
                  type='button'
                  id='{clientProfile._id}'
                  onclick='updateUserEmail(this.id)'
                  className='btn btn-primary assessment-log'>
                  Update Email
                </button>
              )}
              {!clientProfile.coachId && !clientProfile.isCoach && user.admin && (
                <button
                  type='button'
                  id='{clientProfile._id}'
                  onclick='findCoach(this.id)'
                  className='btn btn-primary assessment-log'>
                  Find Coach
                </button>
              )} */}
            </div>
          </div>
          <div className='user-show-description mob-text-center'>
            <h4>{clientProfile.email}</h4>
          </div>
        </div>
      </div>
      <div className='form-wrp-profile'>
        <div className='container'>
          <div className='row'>
            <div className='col-xs-12 col-sm-12'>
              <div className='form-group form-component-wrp'>
                <label className='form-label text-right'></label>
                <div className='custom-templates' id='custom-templates'>
                  <h3>Platform Information</h3>
                </div>
              </div>
              <div className='timeliner-wrp'>
                <div
                  className={classnames('timeline-presentation', {
                    'timeline-active': timeline.assessmentTaken,
                  })}>
                  <div className='timeline timeline1'></div>
                  <div className='timeline-desc-wrp'>
                    <div className='timeline-desc-label'>Assessment</div>
                    <div className='timeline-description'>
                      The customer has taken the assessment
                    </div>
                  </div>
                </div>
                <div
                  className={classnames('timeline-presentation', {
                    'timeline-active': timeline.reviewCallTaken,
                  })}>
                  <div className='timeline timeline2'></div>
                  <div className='timeline-desc-wrp'>
                    <div className='timeline-desc-label'>
                      {`Review & Growth Plan Call`}
                    </div>
                    <div className='timeline-description'>
                      A coach has conducted this call
                    </div>
                  </div>
                </div>
                <div
                  className={classnames('timeline-presentation', {
                    'timeline-active': timeline.paidForCoaching,
                  })}>
                  <div className='timeline timeline3'></div>
                  <div className='timeline-desc-wrp'>
                    <div className='timeline-desc-label'>
                      Paid For Evolution Coaching
                    </div>
                    <div className='timeline-description'>
                      Payment received and up to date
                    </div>
                  </div>
                </div>
                <div
                  className={classnames('timeline-presentation', {
                    'timeline-active': timeline.sessionTaken,
                  })}>
                  <div className='timeline timeline4'></div>
                  <div className='timeline-desc-wrp'>
                    <div className='timeline-desc-label'>Sessions</div>
                    <div className='timeline-description'>
                      Sessions are underway
                    </div>
                  </div>
                </div>
                <div
                  className={classnames('timeline-presentation', {
                    'timeline-active': timeline.has360Report,
                  })}>
                  <div className='timeline timeline5'></div>
                  <div className='timeline-desc-wrp'>
                    <div className='timeline-desc-label'>360</div>
                    <div className='timeline-description'>
                      A 360 analysis has been completed
                    </div>
                  </div>
                </div>
                <div
                  className={classnames('timeline-presentation', {
                    'timeline-active': timeline.hasImpactReport,
                  })}>
                  <div className='timeline timeline6'></div>
                  <div className='timeline-desc-wrp'>
                    <div className='timeline-desc-label'>Impact Report</div>
                    <div className='timeline-description'>
                      An impact report with quantitative and qualitative
                      insights has been delivered
                    </div>
                  </div>
                </div>
              </div>
              <div className='form-group form-component-wrp'>
                <label className='form-label text-right font-14'>Surveys</label>
                <div className='custom-templates' id='custom-templates'>
                  {clientSurveyList.length > 0
                    ? clientSurveyList.map((s) => (
                        <div key={s._id}>
                          {clientSurveyList.length > 1 && (
                            <input
                              type='checkbox'
                              onClick={() => {
                                let arr = [...surveyList]
                                const index = arr.findIndex((e) => e === s._id)
                                if (index > -1) {
                                  arr.splice(index, 1)
                                } else {
                                  arr.push(s._id)
                                }
                                setSurveyList(arr)
                              }}
                            />
                          )}
                          <NavLink
                            to={`/survey/${s._id}`}
                            className='text-dark-600'>
                            {moment(s.createdAt).format('MM/DD/YYYY')}
                          </NavLink>{' '}
                          - SA: {s.results.self_awareness.percent}%, TC:{' '}
                          {s.results.team_cohesion.percent}%, VI:{' '}
                          {s.results.vision__impact.percent}%
                          <button
                            type='button'
                            className='btn btn-primary btn-small assessment-log compare-btn'>
                            Review Call
                          </button>
                          <button
                            type='button'
                            className='btn btn-primary btn-small assessment-log compare-btn'>
                            Call Outcome
                          </button>
                        </div>
                      ))
                    : 'No surveys'}
                  {clientSurveyList.length > 1 && (
                    <button
                      className='btn btn-primary btn-small compare-btn'
                      onClick={() => compare()}
                      disabled={surveyList.length !== 2}>
                      Compare
                    </button>
                  )}
                </div>
              </div>
              <div className='form-group form-component-wrp'>
                <label className='form-label text-right font-14'>
                  Coaching Plan
                </label>
                <div className='custom-templates' id='custom-templates'>
                  {clientProfile.coachId ? (
                    <NavLink
                      to={`/coach/clients/${clientProfile._id}/plan`}
                      className='text-dark-600'>
                      {hasPlan ? 'View Plan' : 'Add Plan'}
                    </NavLink>
                  ) : (
                    'Please assign a coach to add plan'
                  )}
                </div>
              </div>
              <SessionList
                clientId={clientId}
                clientProfile={clientProfile}
                loggedInUser={user}
              />
              <Reports
                clientId={clientId}
                clientProfileId={clientProfile?.id}
                loggedInUserIsAdmin={user?.admin || user?.isAdmin}
              />
              {clientProfile?.profile?.shareGrowthPlanWithCoach && (
                <div className='form-group form-component-wrp'>
                  <label className='form-label text-right font-14'>
                    Growth Plan
                  </label>
                  <div className='custom-templates' id='custom-templates'>
                    <NavLink
                      to={`/coach/clients/${clientProfile._id}/growth-plan`}
                      className='text-dark-600'>
                      View
                    </NavLink>
                  </div>
                </div>
              )}
              {user.isCoach && !(user.admin || user.isAdmn) && (
                <>
                  <div className='form-group form-component-wrp'>
                    <label className='form-label text-right'>
                      Platform Plan
                    </label>
                    <div className='custom-templates' id='custom-templates'>
                      {clientProfile?.planCode?.charAt(0).toUpperCase() +
                        clientProfile?.planCode?.slice(1)}
                    </div>
                  </div>
                  <div className='form-group form-component-wrp'>
                    <label className='form-label text-right'>PLAN START</label>
                    <div className='custom-templates' id='planStartDate'>
                      {formatDate(clientProfile.createdAt)}
                    </div>
                  </div>
                  <div className='form-group form-component-wrp'>
                    <label className='form-label text-right'>PLAN END</label>
                    <div
                      className='col-lg-3 col-md-3 col-sm-12 col-xs-12 pl-0 custom-templates'
                      id='custom-templates'>
                      {clientProfile.expiresOn ? (
                        <input
                          className='form-control'
                          type='date'
                          id='planEndDate'
                          placeholder='First Session Date'
                          name='firstSessionDate'
                          onChange={(e) => updatePlanExpiry(e.target.value)}
                          value={moment(expiresOn).format('YYYY-MM-DD')}
                          disabled={viewOnly || planExpiryLoader}
                          data-date-format='YYYY-MM-DD'
                          min={moment(clientProfile.createdAt).format(
                            'YYYY-MM-DD',
                          )}
                          required
                        />
                      ) : (
                        'Not paid'
                      )}
                    </div>
                  </div>
                  <div className='form-group form-component-wrp'>
                    <label className='form-label text-right'>Promo Code</label>
                    <div className='custom-templates' id='custom-templates'>
                      {clientProfile.promoCode ? (
                        <a
                          href={`/admin/promo/${clientProfile.promoCode}`}
                          className='text-dark-600'>
                          {clientProfile.promoCode}
                        </a>
                      ) : (
                        'No Code Applied'
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <section>
        <div className='container'>
          <PersonalInformation clientId={clientId} />
        </div>
      </section>
    </>
  )
}

export default withTranslation('translation')(ClientProfile)
