import React from 'react'
import { useFormikContext } from 'formik'
import SelectionField from './SelectionField'
import Labels from '../../constants/labels'

const CurrentStatusField = ({ labelProps }) => {
  const { setFieldValue, handleChange } = useFormikContext()
  const onChange = (e) => {
    handleChange(e)
    if (e.target.value === Labels.userStatus[0]) {
      setFieldValue('companyName', '')
      setFieldValue('roleInCompany', '')
      setFieldValue('companyType', '')
      setFieldValue('numberOfPeopleInCompany', '')
      setFieldValue('investmentStage', '')
      setFieldValue('virtualWorkers', '')
    }
  }
  return (
    <SelectionField
      label='CURRENT STATUS'
      labelProps={labelProps}
      name='workStatus'
      options={Labels.userStatus}
      onChange={onChange}
      disabled
    />
  )
}

export default CurrentStatusField
