"use strict";

var _interopRequireDefault = require("D:/Code/abroad-client/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _classCallCheck2 = _interopRequireDefault(require("D:/Code/abroad-client/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/classCallCheck"));

var _coachUser = _interopRequireDefault(require("./coach/coachUser"));

var _coachingSession = _interopRequireDefault(require("./coach/coachingSession"));

var _coachClient = _interopRequireDefault(require("./coach/coachClient"));

var _RestClient = _interopRequireDefault(require("./RestClient"));

var _team = _interopRequireDefault(require("./coach/team"));

var _document = _interopRequireDefault(require("./coach/document"));

var Coach = function Coach() {
  var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  (0, _classCallCheck2["default"])(this, Coach);
  var restClient = new _RestClient["default"]({
    baseUrl: options.baseUrl
  });
  this.coachUser = new _coachUser["default"](restClient);
  this.coachClient = new _coachClient["default"](restClient);
  this.coachingSession = new _coachingSession["default"](restClient);
  this.teams = new _team["default"](restClient);
  this.document = new _document["default"](restClient);
};

exports["default"] = Coach;